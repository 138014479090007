import Home from "../pages/home/home";
import {routeCreator} from "../helpers/routeCreator";
import Login from "../pages/login/login";
import PrivacyPolicy from "../pages/privacyPolicy/privacyPolicy";
import Tiresias from "../pages/tiresias/tiresias";
import History from "../pages/history/history";

export const ROUTE_PAGE_LOGIN = routeCreator(Login, '/', 'login', 'Login', 'page.login')
export const ROUTE_PAGE_HOME = routeCreator(Home, '/', 'Home', 'page.home', {exact: true})
export const ROUTE_PAGE_PRIVACY_POLICY = routeCreator(PrivacyPolicy, '/privacy/policy', 'privacy', 'PrivacyPolicy', {exact: true})
export const ROUTE_PAGE_TIRESIAS = routeCreator(Tiresias, '/tiresias', 'tiresias', 'Tiresias', {exact: true})
export const ROUTE_PAGE_HISTORY = routeCreator(History, '/history', 'history', 'History', {exact: true})

const router = {
    ROUTE_PAGE_HOME,
    ROUTE_PAGE_PRIVACY_POLICY,
    ROUTE_PAGE_TIRESIAS,
    ROUTE_PAGE_HISTORY
}

export default router;