export * from "./currentUser/types"

export const GET_ACTIVE_EXHIBITIONS = 'GET_ACTIVE_EXHIBITIONS'
export const GET_ACTIVE_EXHIBITIONS_SUCCEEDED = 'GET_ACTIVE_EXHIBITIONS_SUCCEEDED'
export const GET_ACTIVE_EXHIBITIONS_FAILED = 'GET_ACTIVE_EXHIBITIONS_FAILED'

export const GET_EXHIBITION_DETAILS = 'GET_EXHIBITION_DETAILS'
export const GET_EXHIBITION_DETAILS_SUCCEEDED = 'GET_EXHIBITION_DETAILS_SUCCEEDED'
export const GET_EXHIBITION_DETAILS_FAILED = 'GET_EXHIBITION_DETAILS_FAILED'

export const CREATE_VISITOR = 'CREATE_VISITOR'
export const CREATE_VISITOR_SUCCEEDED = 'CREATE_VISITOR_SUCCEEDED'
export const CREATE_VISITOR_FAILED = 'CREATE_VISITOR_FAILED'

export const GET_COORGANIZATION_INTERESTS = 'GET_COORGANIZATION_INTERESTS'
export const GET_COORGANIZATION_INTERESTS_SUCCEEDED = 'GET_COORGANIZATION_INTERESTS_SUCCEEDED'
export const GET_COORGANIZATION_INTERESTS_FAILED = 'GET_COORGANIZATION_INTERESTS_FAILED'

export const GET_EXHIBITION_INTERESTS = 'GET_EXHIBITION_INTERESTS'
export const GET_EXHIBITION_INTERESTS_SUCCEEDED = 'GET_EXHIBITION_INTERESTS_SUCCEEDED'
export const GET_EXHIBITION_INTERESTS_FAILED = 'GET_EXHIBITION_INTERESTS_FAILED'

export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_COUNTRIES_SUCCEEDED = 'GET_COUNTRIES_SUCCEEDED'
export const GET_COUNTRIES_FAILED = 'GET_COUNTRIES_FAILED'

export const SET_USER_ALTER_PASSWORD = 'SET_USER_ALTER_PASSWORD'


export const SET_MENU = 'SET_MENU'
export const SET_MENU_ACTIVE_ITEM = 'SET_MENU_ACTIVE_ITEM'
export const SET_MENU_ACTIVE_ID = 'SET_MENU_ACTIVE_ID'
export const SET_MENU_ACTIVE_COMPONENT = 'SET_MENU_ACTIVE_COMPONENT'
export const SET_DRAWER_OPEN = 'SET_DRAWER_OPEN'
export const SET_DRAWER_COMPONENT_OPEN = 'SET_DRAWER_COMPONENT_OPEN'

export const GOOGLE_LOGIN = 'GOOGLE_LOGIN'
export const GOOGLE_LOGIN_SUCCEEDED = 'GOOGLE_LOGIN_SUCCEEDED'
export const GOOGLE_LOGIN_FAILED = 'GOOGLE_LOGIN_FAILED'