import React from "react";
import history from './store/history'
import {ConnectedRouter} from 'connected-react-router'
import {loadModules} from "./store/loadModule";
import {PermissionsModule} from "./modules/permission";
import {blue, green, grey, orange, red} from '@mui/material/colors';
import Main from "./pages/main/main";
import {UsersModule} from "./modules/users";
import {closeSnackbar, SnackbarProvider} from 'notistack';
import {MaterialDesignContent} from 'notistack'
import {styled} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import {CancelOutlined} from "@mui/icons-material";
import ThemeCustomization from "./theme/mantis/themes";
import ScrollTop from "./theme/mantis/components/scrollTop";
import {ParametersModule} from "./modules/parameters";

export const ENABLED_MODULES = loadModules([PermissionsModule, UsersModule, ParametersModule])
export const ROUTES = ENABLED_MODULES.loadRoutes()

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
        backgroundColor: green[500]
    },
    '&.notistack-MuiContent-warning': {
        backgroundColor: orange[700]
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: red[400]
    },
    '&.notistack-MuiContent-info': {
        backgroundColor: blue[400]
    },
}));

function App() {
    return (
        <ThemeCustomization>
            <>
                <SnackbarProvider
                    maxSnack={8}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    Components={{
                        success: StyledMaterialDesignContent,
                        error: StyledMaterialDesignContent,
                        warning: StyledMaterialDesignContent,
                        info: StyledMaterialDesignContent
                    }}
                    dense={true}
                    autoHideDuration={8000}
                    preventDuplicate={true}
                    action={(snackbarId) => (
                        <IconButton
                            onClick={() => closeSnackbar(snackbarId)}
                            sx={{
                                color: grey[900]
                            }}
                        >
                            <CancelOutlined/>
                        </IconButton>
                    )}
                >
                    <ConnectedRouter history={history}>
                        <ScrollTop>
                            <Main/>
                        </ScrollTop>
                    </ConnectedRouter>
                </SnackbarProvider>
            </>
        </ThemeCustomization>
    )
}

export default App
