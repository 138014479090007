import useUserData from "../../hooks/user/useUserData";
import useUserAction from "../../hooks/user/useUserAction";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import axios from "axios";
import {API} from "../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../helpers/errorHandling";
import {useFormik} from "formik";
import {PAGE_TIRESIAS} from "../../locales/pages/namespaces";
import queryString from "query-string";
import {useState} from "react";

const useTiresias = () => {
    const {token, isLoggedIn} = useUserData()
    const {userLogout} = useUserAction()
    const {t} = useTranslation(PAGE_TIRESIAS)
    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = yup.object({
        name: yup
            .string()
            .required(t('Required')),
        surname: yup
            .string()
            .required(t(t('Required'))),
        eMail: yup
            .string()
            .required(t('Required'))
            .email(t('Enter a valid email')),
        fathername: yup
            .string()
            .required(t('Required')),
        mobile: yup
            .string()
            .required(t('Required')),
        identityNo: yup
            .string()
            .required(t('Required')),
        vat: yup
            .string()
            .required(t('Required')),
        govAuthVerificationCode: yup
            .string()
            .required(t('Required')),

    })
    // const {fetchFile} = useAuthenticatedFile()
    const onSubmit = (values) => {
        setIsLoading(true)
        const queryParams = {
            surname: values.surname,
            name: values.name,
            fathername: values.fathername,
            identityNo: values.identityNo,
            mobile: values.mobile,
            eMail: values.eMail,
            vat: values.vat,
            govAuthVerificationCode: values.govAuthVerificationCode,
        };

        const qs = queryString.stringify(queryParams);

        const urlWithQueryParams = `${API}/teiresias?${qs}`;

        axios({
            method: 'get',
            data: {
                surname: values.surname,
                name: values.name,
                fathername: values.fathername,
                identityNo: values.identityNo,
                mobile: values.mobile,
                eMail: values.eMail,
                vat: values.vat,
                govAuthVerificationCode: values.govAuthVerificationCode,
            },
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            //url: `${API}/teiresias`,
            url: urlWithQueryParams,
            responseType: 'blob'
        }).then(response => {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);

            // Create a link element
            const a = document.createElement('a');
            a.href = url;
            a.download = 'downloaded.pdf'; // Set the filename for download
            a.target = '_blank'; // Open in a new tab
            a.style.display = 'none';

            // Append the link element to the document and trigger the click event
            document.body.appendChild(a);
            a.click();

            // Open a new tab with the PDF
            window.open(url, '_blank');

            // Clean up by revoking the blob URL
            window.URL.revokeObjectURL(url);

            enqueueSnackbar(t('Successfully downloaded PDF!'), {
                variant: 'success',
                autoHideDuration: 800,
            });
            setIsLoading(false);
            formik.resetForm()
        }).catch(error => {
            enqueueSnackbar(handleAxiosErrors(error, t('Failed to download PDF.'), {
                    variant: 'error',
                }
            ))
            setIsLoading(false)
            logoutUnauthorizedUser(error, isLoggedIn, userLogout)
        })
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            surname: '',
            fathername: '',
            identityNo: '',
            mobile: '',
            eMail: '',
            vat: '',
            govAuthVerificationCode: ''
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    })

    return {
        formik,
        isLoading
    }
}
export default useTiresias