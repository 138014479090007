// @flow
import * as React from 'react';
import {ErrorMessage, FormikProvider} from "formik";
import {USERS} from "../../locales/namespaces";
import {useTranslation} from "react-i18next";
import {
    Box, Button, FormControl, FormControlLabel, FormHelperText, Grid,
    FormLabel, Select, Stack, Toolbar, TextField, MenuItem, IconButton,
    InputAdornment, Typography, Divider, Autocomplete, Switch
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_USERS_DETAILS, ROUTE_PAGE_USERS_INDEX} from "../../routes/routes";
import useUserForm from "../../hooks/useUserForm";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {ROUTE_PAGE_HOME} from "../../../../routers/routes";
import useUserData from "../../../../hooks/user/useUserData";
import {textTranslate} from "../../../../helpers/helpers";
import {useMemo} from "react";
import {Link} from "react-router-dom";
import PermissionHOC from "../../../permission/components/permissionHOC/permissionHOC";
import MainCard from "../../../../theme/mantis/components/mainCard";
import ContentLoader from "../../../../components/contentLoader/contentLoader";

type Props = {
    id: string,
    user?: Object,
    countries: Array,
    currencies: Array,
    languages: Array,
    nationalities: Array
}

const Form = (props: Props) => {
    const {t} = useTranslation(USERS)
    const {
        id,
        user,
        countries,
        currencies,
        nationalities,
        languages
        //dateFormats
    } = props
    const {language} = useUserData()
    const {formik, showPassword, handleClickShowPassword, handleMouseDownPassword, isLoading} = useUserForm({
        id,
        user,
        languages,
        countries,
        currencies,
        nationalities
    })

    const nationalityList = useMemo(() => (
        nationalities.map(item => (
            <MenuItem
                key={`nationality-${item.id}`}
                value={item.id}
            >
                {textTranslate(language)({
                    en: item.DescriptionEn,
                    el: item.Description
                })}
            </MenuItem>
        ))), [nationalities, language])

    return !isLoading ? (
        <FormikProvider value={formik}>
            <PermissionHOC requiredPermissions={['access_user_index']}>
                <Breadcrumb>
                    <Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
                        {t('Home Page')}
                    </Breadcrumb.Link>
                    <Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_USERS_INDEX)}>
                        {t('Users')}
                    </Breadcrumb.Link>
                    <Breadcrumb.Item>
                        {!id ? t('Create') : t('Edit')}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </PermissionHOC>
            <MainCard sx={{maxWidth: 800}} title={
                <Toolbar disableGutters variant={"dense"}>
                    <Typography variant={"h4"} sx={{ml: 2}}>
                        {!id ? t('Create User') : t('Edit Profile')}
                    </Typography>
                    <Box sx={{flexGrow: 1}}/>
                </Toolbar>}>
                <Grid container>
                    <PermissionHOC requiredPermissions={['edit_usr_isactive', 'view_usr_isactive']}>
                        <Grid item xs={12} md={6} ld={6}>
                            <Stack spacing={1}>
                                <FormControl variant="standard" fullWidth>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={formik.values.isActive}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                size={'small'}
                                                name={'isActive'}
                                            />
                                        }
                                        label={t('Active')}
                                        labelPlacement="end"
                                    />
                                </FormControl>
                            </Stack>
                        </Grid>
                    </PermissionHOC>
                </Grid>
                <Grid sx={{flexGrow: 1}} container spacing={2}>
                    <Grid item lg={6}>
                        <Grid container spacing={1} sx={{mr: 2}}>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={
                                            formik.touched["LoginName"] &&
                                            Boolean(formik.errors["LoginName"])
                                        }
                                    >
                                        <FormLabel htmlFor="LoginName" required>{t('Name')}</FormLabel>
                                        <TextField
                                            id="LoginName"
                                            placeholder={t("Enter name")}
                                            name={`LoginName`}
                                            value={formik.values.LoginName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            required
                                            error={formik.touched.LoginName && Boolean(formik.errors.LoginName)}
                                            color={"grey"}
                                            size={'small'}
                                            fullWidth
                                            inputProps={{maxLength: 100}}
                                        />
                                        <FormHelperText>
                                            <ErrorMessage name={"LoginName"}/>
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={
                                            formik.touched["username"] &&
                                            Boolean(formik.errors["username"])
                                        }>
                                        <FormLabel htmlFor="username" required>{t('User Name')}</FormLabel>
                                        <TextField
                                            name={`username`}
                                            placeholder={t("Enter username")}
                                            required
                                            error={formik.touched.username && Boolean(formik.errors.username)}
                                            value={formik.values.username}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            color={"grey"}
                                            size={'small'}
                                            fullWidth
                                            inputProps={{maxLength: 100}}
                                        />
                                        <FormHelperText>
                                            <ErrorMessage name={"username"}/>
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={
                                            formik.touched["password"] &&
                                            Boolean(formik.errors["password"])
                                        }
                                    >
                                        <FormLabel htmlFor="password" required={!id}>{t('Password')}</FormLabel>
                                        <TextField
                                            placeholder={t("Enter password")}
                                            type={showPassword ? 'text' : 'password'}
                                            value={formik.values.password}
                                            name={`password`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            fullWidth
                                            autoComplete="new-password"
                                            color={"grey"}
                                            size={'small'}
                                            required
                                            error={formik.touched.password && Boolean(formik.errors.password)}
                                            InputProps={{
                                                minLength: 7,
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                        />
                                        <FormHelperText>
                                            <ErrorMessage name={"password"}/>
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={
                                            formik.touched["confirmPassword"] &&
                                            Boolean(formik.errors["confirmPassword"])
                                        }
                                    >
                                        <FormLabel htmlFor="confirmPassword"
                                                   required={!id}>{t('Confirm Password')}</FormLabel>
                                        <TextField
                                            placeholder={t("Enter confirm password")}
                                            type={showPassword ? 'text' : 'password'}
                                            value={formik.values.confirmPassword}
                                            name={`confirmPassword`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            fullWidth
                                            color={"grey"}
                                            size={'small'}
                                            autoComplete="on"
                                            required
                                            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                            InputProps={{
                                                minLength: 7,
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                        />
                                        <FormHelperText>
                                            <ErrorMessage name={"confirmPassword"}/>
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={
                                            formik.touched["email"] &&
                                            Boolean(formik.errors["email"])
                                        }
                                    >
                                        <FormLabel htmlFor="email" required>{t('Email')}</FormLabel>
                                        <TextField
                                            placeholder={t("Enter email")}
                                            type={'email'}
                                            value={formik.values.email}
                                            name={`email`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            fullWidth
                                            color={"grey"}
                                            size={'small'}
                                            required
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            inputProps={{
                                                maxLength: 150,
                                                autoComplete: 'off',
                                            }}
                                        />
                                        <FormHelperText>
                                            <ErrorMessage name={"email"}/>
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Grid container spacing={1} sx={{mr: 2}}>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        error={
                                            formik.touched["country"] &&
                                            Boolean(formik.errors["country"])
                                        }
                                    >
                                        <FormLabel htmlFor="country" required>{t('Country')}</FormLabel>
                                        <Autocomplete
                                            options={countries}
                                            value={formik.values.country}
                                            isOptionEqualToValue={(opt, val) => {
                                                return opt.id === val.id || !val
                                            }}
                                            getOptionLabel={option => textTranslate(language)({
                                                en: option.DescriptionEn,
                                                el: option.Description
                                            })}
                                            onChange={(event, newValue) => {
                                                formik.setFieldValue("country", newValue)
                                            }}
                                            onBlur={formik.handleBlur}
                                            size="small"
                                            fullWidth
                                            renderInput={(params) => (
                                                <TextField
                                                    placeholder={t("Choose country")}
                                                    size={"small"}
                                                    {...params}
                                                    required
                                                    error={formik.touched.country && Boolean(formik.errors.country)}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'off',
                                                    }}
                                                />
                                            )}
                                        />
                                        <FormHelperText sx={{marginLeft: 0}}>
                                            <ErrorMessage name={"country"}/>
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        error={
                                            formik.touched["nationality"] &&
                                            Boolean(formik.errors["nationality"])
                                        }
                                    >
                                        <FormLabel htmlFor="nationality" required>{t('Nationality')}</FormLabel>
                                        <Select
                                            labelId="nationality"
                                            autoComplete={"chrome-off"}
                                            size={"small"}
                                            name={"nationality"}
                                            value={formik.values.nationality}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        >
                                            {nationalityList}
                                        </Select>
                                        <FormHelperText>
                                            <ErrorMessage name={"nationality"}/>
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        error={
                                            formik.touched["currency"] &&
                                            Boolean(formik.errors["currency"])
                                        }
                                    >
                                        <FormLabel htmlFor="currency" required>{t('Currency')}</FormLabel>
                                        <Select
                                            labelId="currency"
                                            autoComplete={"chrome-off"}
                                            size={"small"}
                                            name={"currency"}
                                            value={formik.values.currency}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        >
                                            {currencies.map(item => (
                                                <MenuItem key={`currency-${item.id}`}
                                                          value={item.id}>{item.Description}</MenuItem>))}
                                        </Select>
                                        <FormHelperText>
                                            <ErrorMessage name={"currency"}/>
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        error={
                                            formik.touched["language"] &&
                                            Boolean(formik.errors["language"])
                                        }
                                    >
                                        <FormLabel htmlFor="language" required>{t('Language')}</FormLabel>
                                        <Autocomplete
                                            options={languages}
                                            value={formik.values.language}
                                            isOptionEqualToValue={(opt, val) => {
                                                return opt.id === val.id || !val
                                            }}
                                            getOptionLabel={option => option?.Description}
                                            onChange={(event, newValue) => {
                                                formik.setFieldValue("language", newValue)
                                            }}
                                            onBlur={formik.handleBlur}
                                            size="small"
                                            fullWidth
                                            renderInput={(params) => (
                                                <TextField
                                                    placeholder={t("Choose language")}
                                                    size={"small"}
                                                    {...params}
                                                    required
                                                    error={formik.touched.language && Boolean(formik.errors.language)}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'off',
                                                    }}
                                                />
                                            )}
                                        />
                                        <FormHelperText sx={{marginLeft: 0}}>
                                            <ErrorMessage name={"language"}/>
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider light sx={{mt: 2}}/>
                <Button
                    size={"small"}
                    variant={"contained"}
                    sx={{float: "right", mb: 2, mt: 2}}
                    onClick={formik.submitForm}
                >
                    {t('Save')}
                </Button>
                <Button size={"small"}
                        variant={"contained"}
                        color={"error"}
                        component={Link}
                        to={!id ? getRouteUrl(ROUTE_PAGE_USERS_INDEX) : getRouteUrl(ROUTE_PAGE_USERS_DETAILS, {id: id})}
                        sx={{float: "right", mr: 1, mt: 2, minWidth: '95px'}}
                >
                    {t('Cancel')}
                </Button>
            </MainCard>
        </FormikProvider>
    ) : (
        <ContentLoader
            withBackDrop={true}
            useCircularLoader={true}
            loading={isLoading}
            message={t("Loading... Please don't close the browser before the process ends.")}
        />
    )
}
export default Form