// @flow
import React from 'react';
import {Toolbar, Grid, Button, List, ListItem, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_USERS_EDIT} from "../../routes/routes";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {useTranslation} from "react-i18next";
import {USERS} from "../../locales/namespaces";
import {textTranslate} from "../../../../helpers/helpers";
import useUserData from "../../../../hooks/user/useUserData";
import PermissionHOC from "../../../permission/components/permissionHOC/permissionHOC";
import MainCard from "../../../../theme/mantis/components/mainCard";

const Profile = (props) => {
    const {id, userData} = props
    const {t} = useTranslation(USERS)
    const {language} = useUserData()
    const theme = useTheme();
    const matchDownMD = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

    console.log(id)
    return (
        <MainCard title={
            <Toolbar disableGutters variant={"dense"}>
                <Grid container justifyContent="space-between" alignItems="center"
                      sx={{position: 'relative', zIndex: 5}}>
                    <Grid item>
                        <Typography variant="h5" gutterBottom>
                            {t('Information')}
                        </Typography>
                    </Grid>
                    <Grid item sx={{mx: matchDownSM ? 2 : 3, my: matchDownSM ? 1 : 0, mb: matchDownSM ? 2 : 0}}
                          xs={matchDownSM ? 12 : 'auto'}>
                        <Button
                            size={"small"}
                            variant={"contained"}
                            component={Link}
                            to={(getRouteUrl(ROUTE_PAGE_USERS_EDIT, {id: id}))}
                             >
                            {t('Edit')}
                        </Button>
                    </Grid>
                </Grid>
            </Toolbar>
        }
        >
            <List sx={{py: 0}}>
                <PermissionHOC requiredPermissions={['edit_cust_isactive', 'view_cust_isactive']}>
                    <ListItem divider={!matchDownMD}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={0.5}>
                                    <Typography color="secondary" sx={{fontWeight: "bold"}}>{t('Active')}</Typography>
                                    <Typography>{userData.isActive === '1' ?
                                        <CheckOutlinedIcon/> : <CloseOutlinedIcon/>}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={0.5}>
                                    <Typography color="secondary" sx={{fontWeight: "bold"}}>{t('Origin')}</Typography>
                                    <Typography>{userData.OriginDescription}</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </ListItem>
                </PermissionHOC>
                <ListItem divider={!matchDownMD}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                                <Typography color="secondary" sx={{fontWeight: "bold"}}>{t('Name')}</Typography>
                                <Typography>{userData.LoginName}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                                <Typography color="secondary" sx={{fontWeight: "bold"}}>{t('User Name')}</Typography>
                                <Typography>{userData.username}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </ListItem>
                <ListItem divider={!matchDownMD}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                                <Typography color="secondary" sx={{fontWeight: "bold"}}>{t('E-mail')}</Typography>
                                <Typography>{userData.email}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </ListItem>
                <ListItem divider={!matchDownMD}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                                <Typography color="secondary" sx={{fontWeight: "bold"}}>{t('Country')}</Typography>
                                <Typography>{userData.Country}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                                <Typography color="secondary" sx={{fontWeight: "bold"}}>{t('Nationality')}</Typography>
                                <Typography>{textTranslate(language)({
                                    en: userData.NationalityEn,
                                    el: userData.NationalityEl
                                })}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </ListItem>
                <ListItem divider={!matchDownMD}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                                <Typography color="secondary" sx={{fontWeight: "bold"}}>{t('Language')}</Typography>
                                <Typography>{userData.Language}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                                <Typography color="secondary" sx={{fontWeight: "bold"}}>{t('Currency')}</Typography>
                                <Typography>{userData.Currency}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </ListItem>
            </List>
        </MainCard>
    )
}

export default Profile