export const textTranslate = (language) => (item = {en: '', el: ''}) => {
	return item[language]
}

export function replaceDotWithComma(text, language) {
	if (language === 'el') {
		// Replace all occurrences of '.' with ','
		return text !== null && text.replace(/\./g, ',');
	}
	// If the language is not 'el', return the original text
	return text;
}

export const toCamelCase = (string) => {
	if (!string) return ''
	const newStringArray = []
	const stringArray = string.split(' ')
	for (let stringPart of stringArray) {
		const firstChar = stringPart[0]
		const afterFirstChar = stringPart.substring(1, stringPart.length)
		newStringArray.push(firstChar.toUpperCase() + afterFirstChar.toLocaleLowerCase())
	}
	return newStringArray.join(' ')
}

//  function formatNumberForLanguage(number, language) {
//   if (language === 'el') {
//     // Convert number to a string and split it into integer and decimal parts
//
//     const [integerPart, decimalPart] = number.toString().split('.');
//
//     // If there's a decimal part, replace '.' with ','
//     if (decimalPart) {
//
//       return `${integerPart}.${decimalPart.replace('.', ',')}`;
//     } else {
//       return number.toString(); // No decimal part, return as is
//     }
//   }
//
//   // If the language is not 'el', return the number as is
//   return number.toString();
// }

// export function formatNumber(number, language) {
//     if (language === 'el') {
//         // For Greek language, format the number with ',' as the thousands separator and '.' as the decimal separator
//         return number.toLocaleString('el', { style: 'decimal', useGrouping: true });
//     } else {
//         // For other languages (English and others), use the default formatting with ',' as the thousands separator and '.' as the decimal separator
//         return number.toLocaleString();
//     }
// }
