// material-ui
import {List, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';

// assets
import {LogoutOutlined} from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import {NAVIGATION} from "../../../../../../../locales/components/namespaces";
// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({handleLogout}) => {
	const {t} = useTranslation(NAVIGATION)
	return (
		<List component="nav" sx={{p: 0, '& .MuiListItemIcon-root': {minWidth: 32}}}>
			<ListItemButton onClick={handleLogout}>
				<ListItemIcon>
					<LogoutOutlined/>
				</ListItemIcon>
				<ListItemText primary={t("Logout")}/>
			</ListItemButton>
		</List>
	);
};

export default ProfileTab;
