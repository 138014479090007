import {useRef, useState} from 'react';
// material-ui
import {
    Box,
    ButtonBase,
    CardContent,
    ClickAwayListener,
    Grid,
    Paper,
    Popper,
    Stack,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';

// project import
import ProfileTab from './profileTab';

// assets
import useUserData from "../../../../../../../hooks/user/useUserData";
import useUserAction from "../../../../../../../hooks/user/useUserAction";
import {ThemeMode} from "../../../../../config";
import Transitions from "../../../../../components/@extended/transitions";
import MainCard from "../../../../../components/mainCard";
import Avatar from "../../../../../components/@extended/avatar";
import user from '../../../../../assets/images/users/user.png';

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
    const theme = useTheme();
    const {loginName} = useUserData()
    const {userLogout} = useUserAction()

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'grey.200' : 'grey.300';

    return (
        <Box sx={{flexShrink: 0, ml: 0.75}}>
            <ButtonBase
                sx={{
                    p: 0.25,
                    bgcolor: open ? iconBackColorOpen : 'transparent',
                    borderRadius: 1,
                    '&:hover': {
                        bgcolor: theme.palette.mode === ThemeMode.DARK ? 'secondary.light' : 'secondary.lighter',
                        color: theme.palette.mode === ThemeMode.DARK ? 'inherit' : 'primary.darker'
                    },
                    '&:focus-visible': {
                        outline: `2px solid ${theme.palette.secondary.dark}`,
                        outlineOffset: 2
                    }
                }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Stack direction="row" spacing={2} alignItems="center" sx={{p: 0.5}}>
                    <Avatar alt="profile user" src={user} size="xs"/>
                    <Typography variant="subtitle1">{loginName}</Typography>
                </Stack>
            </ButtonBase>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9]
                            }
                        }
                    ]
                }}
            >
                {({TransitionProps}) => (
                    <Transitions type="grow" position="top-right" in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                boxShadow: theme.customShadows.z1,
                                width: 290,
                                minWidth: 240,
                                maxWidth: 290,
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 250
                                }
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard elevation={0} border={false} content={false}>
                                    <ProfileTab handleLogout={userLogout}/>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default Profile;
