import * as ActionTypes from '../../actions/types'
import {mapUser} from "../../types/user/map";
import {fromJS} from "immutable";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_LOGIN} from "../../routers/routes";

const INITIAL_STATE = {
    token: null,
    language: 'el',
    loading: false,
    errorLogin: null,
    isLoggedIn: false,
    errorConnectivity: null,
    alterPasswordOnLogin: false,
    tokenExpirationDateTime: null,
    data: {
        id: null,
        customerId: null,
        userName: null,
        loginName: null
    },
}

function checkAPIConnectivity(state) {
    return state
        .set('loading', true)
        .set('errorConnectivity', null)
        .set('errorLogin', null)
}

function checkAPIConnectivitySucceeded(state) {
    return state
        .set('loading', false)
        .set('errorConnectivity', null)
        .set('errorLogin', null)
}

function checkAPIConnectivityFailed(state) {
    return state
        .set('loading', false)
        .set('errorConnectivity', 'Access failed!')
        .set('errorLogin', null)
}

function userLogin(state) {
    return state
        .set('errorLogin', null)
        .set('token', null)
        .set('loading', true)
        .set('isLoggedIn', false)
}

function userLoginSucceeded(state, action) {
    const {
        AlterPassword,
        access_token,
        expires_in,
        langcode,
        langid,
        user_id,
        cust_id,
        loginname,
        user_name
    } = action.payload
    const tokenExpirationDateTime = new Date()
    tokenExpirationDateTime.setSeconds(tokenExpirationDateTime.getSeconds() + expires_in)
    const user = mapUser({
        loginName: loginname,
        username: user_name,
        userId: user_id,
        customerId: cust_id,
        languageCode: langcode,
        languageId: langid,
    })
    return state
        .set('errorLogin', null)
        .set('errorConnectivity', null)
        .set('loading', false)
        .set('token', access_token)
        .set('tokenExpirationDateTime', tokenExpirationDateTime)
        .set('isLoggedIn', true)
        .set('language', langcode ? !langcode.includes('el') && !langcode.includes('en') ? 'en' : (langcode.includes('el') ? 'el' : 'en') : INITIAL_STATE.language)
        .set('alterPasswordOnLogin', AlterPassword === '1')
        .set('data', fromJS(user));
}

function userLoginFailed(state, action) {
    const {message} = action.payload
    return state
        .set('errorLogin', message)
        .set('token', null)
        .set('loading', false)
        .set('isLoggedIn', false)
}

function setUserLanguage(state, action) {
    return state
        .set('language', action.payload);
}

function setUserAlterPassword(state, action) {
    const {
        AlterPassword
    } = action.payload
    return state
        .set('alterPasswordOnLogin', AlterPassword === '1')
}

function userLogoutSucceeded(state, action) {
    window.location.href = getRouteUrl(ROUTE_PAGE_LOGIN)
    return state
        .set('errorLogin', null)
        .set('token', null)
        .set('loading', false)
        .set('isLoggedIn', false)
}

function googleLogin(state, action) {
    return state
        .set('errorLogin', null)
        .set('token', null)
        .set('loading', true)
        .set('isLoggedIn', false)
}

function googleLoginSucceeded(state, action) {
    debugger
    return state
        .set('loading', null)
}

function googleLoginFailed(state, action) {
    debugger
    return state
        .set('loading', null)
}

const currentUser = {
    initialState: INITIAL_STATE,
    handlers: {
        [ActionTypes.CHECK_API_CONNECTIVITY]: checkAPIConnectivity,
        [ActionTypes.CHECK_API_CONNECTIVITY_SUCCEEDED]: checkAPIConnectivitySucceeded,
        [ActionTypes.CHECK_API_CONNECTIVITY_FAILED]: checkAPIConnectivityFailed,
        [ActionTypes.USER_LOGIN]: userLogin,
        [ActionTypes.USER_LOGIN_SUCCEEDED]: userLoginSucceeded,
        [ActionTypes.USER_LOGIN_FAILED]: userLoginFailed,
        [ActionTypes.USER_LOGOUT_SUCCEEDED]: userLogoutSucceeded,
        [ActionTypes.SET_USER_LANGUAGE]: setUserLanguage,
        [ActionTypes.SET_USER_ALTER_PASSWORD]: setUserAlterPassword,
        [ActionTypes.GOOGLE_LOGIN]: googleLogin,
        [ActionTypes.GOOGLE_LOGIN_SUCCEEDED]: userLoginSucceeded,
        [ActionTypes.GOOGLE_LOGIN_FAILED]: userLoginFailed,
    }
}

export default currentUser