import * as React from "react";
const ErgoLogoMain = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 118 13.143"
        xmlSpace="preserve"
        width={118}
        height={35}
        fill="#ffffff"
        {...props}
    >
        <path
            className="st0"
            d="M105.541 10.731v1.368c0 0.576 0.468 1.044 1.044 1.044h10.37c0.576 0 1.044 -0.468 1.044 -1.044v-1.368zm-14.295 -4.069c0 0.576 0.468 1.044 1.044 1.152h6.338v-2.449h-7.382z"
        />
        <path
            className="st0"
            d="M97.583 0.036h-10.623c-0.576 0 -1.044 0.468 -1.044 1.044v10.983c0 0.576 0.468 1.044 1.044 1.044H97.583c0.576 0 1.044 -0.468 1.044 -1.044v-3.313h-2.449v1.944h-7.886V2.485h10.334V1.08c0.036 -0.576 -0.468 -1.044 -1.044 -1.044m-30.247 10.659h-7.13V4.285l1.656 1.692 0.9 -0.9c0.432 -0.432 0.432 -1.116 0 -1.512l-1.26 -1.26 -1.692 -1.8c-0.288 -0.288 -0.468 -0.468 -0.864 -0.468h-1.188v13.107h11.055V11.883c0 -0.252 -0.108 -0.504 -0.288 -0.684 -0.252 -0.36 -0.72 -0.504 -1.188 -0.504"
        />
        <path
            className="st1"
            d="M0 13.143h10.046c0.576 0 1.044 -0.468 1.044 -1.044v-1.368H0zM0 0.072h10.046c0.576 0 1.044 0.468 1.044 1.044v1.368H0zm0 5.293h5.545c0.576 0 1.044 0.468 1.044 1.044v1.368H0zm24.306 3.385h-2.953c0.144 0.216 0.576 0.828 0.576 0.828 0.324 0.504 0.756 1.116 0.936 1.404s0.612 0.972 0.936 1.476l0.108 0.18c0.324 0.504 1.008 0.648 1.512 0.324l0.18 -0.108c0.504 -0.324 0.648 -1.008 0.324 -1.512z"
        />
        <path
            className="st1"
            d="M25.062 0.036H14.439c-0.576 0 -1.044 0.468 -1.044 1.044v10.983c0 0.576 0.468 1.044 1.044 1.044h0.324c0.576 0 1.044 -0.504 1.044 -1.044V7.814h10.334V1.08c0 -0.576 -0.504 -1.044 -1.08 -1.044m-1.368 5.329H15.808v-2.881h7.886z"
        />
        <path
            className="st0"
            d="M107.918 9.578V2.449h10.082V1.116c0 -0.576 -0.468 -1.044 -1.044 -1.044H106.585c-0.576 0 -1.044 0.468 -1.044 1.044v8.498z"
        />
        <path
            className="st1"
            d="M54.913 0.036h-10.947c-0.504 0 -0.9 0.396 -0.9 0.9v11.271c0 0.504 0.396 0.9 0.9 0.9h10.947c0.504 0 0.9 -0.396 0.9 -0.9V0.972c0 -0.504 -0.396 -0.936 -0.9 -0.936m-1.512 2.449v8.21h-7.886V2.485zM33.56 6.662c0 0.576 0.468 1.044 1.044 1.152h6.338v-2.449H33.56z"
        />
        <path
            className="st1"
            d="M39.897 0.036H29.275c-0.576 0 -1.044 0.468 -1.044 1.044v10.983c0 0.576 0.468 1.044 1.044 1.044h10.623c0.576 0 1.044 -0.468 1.044 -1.044v-3.313h-2.449v1.944H30.607V2.485h10.334V1.08c0.036 -0.576 -0.432 -1.044 -1.044 -1.044"
        />
        <path
            className="st0"
            d="M101.94 0.036c-0.576 0 -1.044 0.468 -1.044 1.008v1.404h2.449V0h-1.404zm-1.08 3.313h2.449v9.794h-2.449zm-18.004 -3.313h-10.947c-0.504 0 -0.9 0.396 -0.9 0.9v11.271c0 0.504 0.396 0.9 0.9 0.9h10.947c0.504 0 0.9 -0.396 0.9 -0.9V0.972c0 -0.504 -0.396 -0.936 -0.9 -0.936m-1.548 2.449v8.21h-7.886V2.485z"
        />
    </svg>
);
export default ErgoLogoMain;
