export default function (theme) {
    return {
        /* components: {
                        MuiFormLabel: {
                            styleOverrides: {
                                root: {
                                    "&.MuiFormLabel-root": {
                                        fontWeight: "bold"
                                    }
                                }
                            }
                        }
                    }*/
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold'
                }
            }
        }
    }
}