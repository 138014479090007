import {Link} from 'react-router-dom';

// material-ui
import {ButtonBase, useMediaQuery} from '@mui/material';
import LogoIcon from "./logoIcon";
import LogoMain from "./logoMain";
import {ROUTE_PAGE_HOME} from "../../../../routers/routes";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import ErgoLogoIcon from "./ergoLogoIcon";
import ErgoLogoMain from "./ergoLogoMain";

// project import


// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({reverse, isIcon, sx, to, isUnathorizedCalc}) => {
    const isMobile = useMediaQuery('(max-width: 800px)');
    return (
        <ButtonBase disableRipple component={Link} to={getRouteUrl(ROUTE_PAGE_HOME)} sx={sx}>
            {isIcon ? (
                /* <LogoIcon/>*/
                <ErgoLogoIcon/>
            ) : (
                isUnathorizedCalc ? (/*<LogoMain
                    reverse={reverse}
                    width={isMobile ? 200 : 250}
                    height={isMobile ? 60 : 80}
                />*/
                        <ErgoLogoMain
                            reverse={reverse}
                            width={isMobile ? 200 : 250}
                            height={isMobile ? 60 : 80}
                        />
                    ) :/* <LogoMain
                    reverse={reverse}
                    width={isMobile ? 250 : 300}
                    height={isMobile ? 150 : 150}
                />*/
                    <ErgoLogoMain
                        reverse={reverse}
                        width={isMobile ? 250 : 300}
                        height={isMobile ? 150 : 150}
                    />
            )}
        </ButtonBase>
    )
}

// LogoSection.propTypes = {
//   reverse: PropTypes.bool,
//   isIcon: PropTypes.bool,
//   sx: PropTypes.object,
//   to: PropTypes.string
// };

export default LogoSection;
