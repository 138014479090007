import Rights from '../pages/rights/rights'
import RightsRoles from '../pages/rightsRoles/rightsRoles'
import Roles from '../pages/roles/roles'
import { routeCreator } from '../../../helpers/routeCreator'

// export const ROUTE_PAGE_PARAMETERS_RIGHTS = routeCreator(Rights, '/parameters/rights', 'Rights', 'page.rights', {exact: true})
// export const ROUTE_PAGE_PARAMETERS_RIGHTS_ROLES = routeCreator(RightsRoles, '/parameters/rights/roles', 'RightsRoles', 'page.rightsRoles', {exact: true})
// export const ROUTE_PAGE_PARAMETERS_ROLES = routeCreator(Roles, '/parameters/roles', 'Roles', 'page.roles', {exact: true})

const router = {
    // ROUTE_PAGE_PARAMETERS_RIGHTS,
    // ROUTE_PAGE_PARAMETERS_RIGHTS_ROLES,
    // ROUTE_PAGE_PARAMETERS_ROLES
}

export default router