//@flow
import React, {useEffect} from 'react'
import useUserData from "../../hooks/user/useUserData";
import moment from "moment";
import 'moment/locale/el'
import useUserAction from "../../hooks/user/useUserAction";
import './main.css';
import {IMAGE_LOGO} from "../../assets/images";
import UnauthenticatedRouter from "../../routers/unauthenticatedRouter";
import Content from "./content";
import {useMultilingualText} from "../../hooks/useMultilingualText";
const Main = () => {
    const {getUserPermissions} = useUserAction()
    const {isLoggedIn, language, alterPasswordOnLogin} = useUserData()
    useEffect(() => {
        moment.locale(language);
    }, [language])
    useEffect(() => {
        if (isLoggedIn) {
            getUserPermissions()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUserPermissions, isLoggedIn])
    const imageUrl = useMultilingualText(IMAGE_LOGO)

    if (!isLoggedIn) return (
        <>
            <UnauthenticatedRouter/>
        </>
    )
    return <Content/>
}

export default Main