import {useDispatch} from "react-redux";
import * as actions from '../../actions/actions'
import {useCallback} from "react";

const useMenuAction = () => {
    const dispatch = useDispatch()
    const setMenu = useCallback((menu) => {
        dispatch(actions.setMenu({menu: menu}))
    }, [dispatch])
    const setMenuActiveItem = useCallback((item) => {
        dispatch(actions.setMenuActiveItem({openItem: [item.id]}))
    }, [dispatch])
    const setMenuActiveId = useCallback((id) => {
        dispatch(actions.setMenuActiveId(id))
    }, [dispatch])
    const setMenuActiveComponent = useCallback((id) => {
        dispatch(actions.setMenuActiveComponent({openComponent: id}))
    }, [dispatch])
    const setDrawerOpen = useCallback((drawerOpen) => {
        dispatch(actions.setDrawerOpen(drawerOpen))
    }, [dispatch])

    const setDrawerComponentOpen = useCallback((componentDrawerOpen) => {
        dispatch(actions.setDrawerComponentOpen({componentDrawerOpen: componentDrawerOpen}))
    }, [dispatch])

    return {
        setMenu,
        setMenuActiveItem,
        setMenuActiveId,
        setMenuActiveComponent,
        setDrawerOpen,
        setDrawerComponentOpen
    }

}

export default useMenuAction