import {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../../helpers/constants";
import useUserData from "../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {ALERTS} from "../../../locales/components/namespaces";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../../hooks/user/useUserAction";

const useCountries = () => {
    const {token, isLoggedIn} = useUserData()
    const {userLogout} = useUserAction()
    const {t} = useTranslation(ALERTS)
    const [loading, setLoading] = useState(false)
    const [countries, setCountries] = useState([])
    const [updateCountries, setUpdateCountries] = useState(false)

    useEffect(() => {
        setLoading(true)
        axios({
            method: 'get',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: `${API}/country/list`,
        }).then((response) => {
            setCountries(response.data)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            enqueueSnackbar(handleAxiosErrors(error, t('Failed to get country list.')), {
                    variant: 'error'
                }
            )
            logoutUnauthorizedUser(error, isLoggedIn, userLogout)
        })
    }, [updateCountries])

    const store = (newRow) => {
        axios({
            method: 'post',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: `${API}/country/store`,
            data: newRow
        }).then(response => {
            enqueueSnackbar(t('New country added successfully!'), {
                    autoHideDuration: 3000,
                    variant: 'success'
                }
            )
            setUpdateCountries(!updateCountries)
        }).catch(error => {
            enqueueSnackbar(handleAxiosErrors(error, t('Failed to store new country!')), {
                    variant: 'error',
                    autoHideDuration: null
                }
            )
            logoutUnauthorizedUser(error, isLoggedIn, userLogout)
        })
    }

    const update = (newRow, oldRow) => {
        axios({
            method: 'post',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: `${API}/country/update`,
            data: newRow
        }).then(response => {
            enqueueSnackbar(t('Country updated successfully!'), {
                    autoHideDuration: 3000,
                    variant: 'success'
                }
            )
            setUpdateCountries(!updateCountries)
        }).catch(error => {
            enqueueSnackbar(handleAxiosErrors(error, t('Failed to update country!')), {
                    variant: 'error',
                    autoHideDuration: null
                }
            )
            logoutUnauthorizedUser(error, isLoggedIn, userLogout)
        })
    }

    const remove = (id) => {
        axios({
            method: 'post',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: `${API}/country/remove`,
            data: {
                id: id
            }
        }).then(response => {
            enqueueSnackbar(t('Country deleted successfully!'), {
                    autoHideDuration: 3000,
                    variant: 'success'
                }
            )
            setUpdateCountries(!updateCountries)
        }).catch(error => {
            enqueueSnackbar(handleAxiosErrors(error, t('Failed to delete country!')), {
                    variant: 'error',
                    autoHideDuration: null
                }
            )
            logoutUnauthorizedUser(error, isLoggedIn, userLogout)
        })
    }

    return {
        loading,
        countries,
        store,
        update,
        remove
    }
}

export default useCountries