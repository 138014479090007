import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {NAVIGATION} from "../../locales/components/namespaces";
import Error404 from "../../theme/mantis/pages/maintenance/404";

const NotFound = () => {
    const {t} = useTranslation(NAVIGATION)
    return (
        <Error404/>
        // <Typography sx={{py: 4, textAlign: "center"}} variant={"h6"}>
        //     {t('PAGE NOT FOUND')}
        // </Typography>
    )
}

export default NotFound;
