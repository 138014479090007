import {useMemo} from 'react';

// material-ui
import {Box, useMediaQuery} from '@mui/material';

// project import
import Profile from './profile';
import Localization from './localization';
import {MenuOrientation} from "../../../../config";
import DrawerHeader from "../../drawer/drawerHeader";
import useConfig from "../../../../hooks/useConfig";
import useUserData from "../../../../../../hooks/user/useUserData";
import MobileSection from "./mobileSection";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    const {i18n, menuOrientation} = useConfig();
    const downLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const localization = useMemo(() => <Localization/>, [i18n]);

    return (
        <>
            {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true}/>}
            {!downLG && <Box sx={{width: '100%', ml: {xs: 0, md: 1}}}/>}
            {!downLG && localization}
            {downLG && <Box sx={{width: '100%', ml: 1}}/>}
            {!downLG && <Profile/>}
            {downLG && <MobileSection/>}
        </>
    );
};

export default HeaderContent;
