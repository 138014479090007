import React from 'react'
import ReactDOM from 'react-dom/client'
import './locales/i18n'
import {Provider} from 'react-redux'
import store, {persistor} from './store/store'
import {PersistGate} from 'redux-persist/es/integration/react'
import App from './App'
import {LicenseInfo} from '@mui/x-license-pro';
import {ConfigProvider} from "./theme/mantis/contexts/configContext";
import 'simplebar/dist/simplebar.css';

LicenseInfo.setLicenseKey('5e6112a021dd7b28e217a41cf5318d20Tz02NzQ2NSxFPTE3MTY5NzgwMjUyMjgsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ConfigProvider>
                        <App/>
                    </ConfigProvider>
                </PersistGate>
            </Provider>
    </React.StrictMode>
)
