//@flow
import React from 'react';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {copyToClipboard} from "../../helpers/copyToClipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {useTranslation} from "react-i18next";
import {COPY_TO_CLIPBOARD} from "../../locales/components/namespaces";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type Props = {
    text: string,
}

const CopyToClipboard = (props: Props) => {
    const {t} = useTranslation(COPY_TO_CLIPBOARD)
    const [open, setOpen] = React.useState(false)
    const [title, setTitle] = React.useState('')
    const handleClose = () => {
        setTimeout(() => {
            setOpen(false)
        }, 1000)
    }
    const handleOpen = () => {
        setTitle(t('Copy'))
        setOpen(true)
    }
    const {text} = props
    return (
        <Box sx={{bgcolor: '#d3d3d3', color: '#000'}}>
            <Typography
                variant={'body2'}
                pl={1}
            >
                {text}
                <Tooltip
                    open={open}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    title={title}
                >
                    <IconButton
                        p={'2px'}
                        color={"inherit"}
                        onClick={(e) => {
                            setTitle(t('Copied'))
                            copyToClipboard(text)
                            e.stopPropagation()
                        }}>
                        <ContentCopyIcon/>
                    </IconButton>
                </Tooltip>
            </Typography>

        </Box>
    )
}

export default CopyToClipboard;