import {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../helpers/constants";
import useUserData from "./user/useUserData";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../helpers/errorHandling";
import useUserAction from "./user/useUserAction";

export const useMuiGridServerSide = ({
                                         url,
                                         axiosMethod,
                                         mapFunction,
                                         initialPageSize,
                                         initialSortModel,
                                         initialFilterModel
                                     }) => {

    const {token, isLoggedIn} = useUserData()
    const {userLogout} = useUserAction()
    const [rows, setRows] = useState({
        data: [],
        total: 0,
        page: 0,
        pageSize: initialPageSize,
        sortModel: initialSortModel,
        filterModel: initialFilterModel
    })
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const onPaginationModelChange = (pagingModel, api) => {
        setRows(old => ({...old, page: pagingModel.page, pageSize: pagingModel.pageSize}))
    }

    const onSortModelChange = (sortModel, mapSortModel = undefined) => {
        const model = sortModel.length > 0 ? sortModel.map(item => ({
            name: item.field,
            direction: item.sort === 'desc' ? 'descending' : 'ascending'
        })) : initialSortModel
        setRows((old) => ({...old, sortModel: model && mapSortModel ? mapSortModel(model) : model}))
    }

    const onFilterModelChange = (filterModel, details, mapFilterModel = undefined) => {
        if(details.reason === 'deleteFilterItem') {
            setRows((old) => ({...old, filterModel: initialFilterModel}))
        } else {
            const predicates = []
            const items = filterModel.items
            for (let item of items) {
                if ((item.hasOwnProperty('value') && item.value !== undefined) || (item.operator === 'isEmpty' || item.operator === 'isNotEmpty')) {
                    if (item.operator === 'isEmpty' || item.operator === 'isNotEmpty') item.value = null
                    // if (typeof item.value === "string" && item.value.match(/[0-9]{4}\-[0-9]{2}\-[0-9]{2}/gm)) {
                    //     switch (item.operator) {
                    //         case 'is':
                    //             item.operator = 'dateIs'
                    //             break
                    //         case 'not':
                    //             item.operator = 'dateNot'
                    //             break
                    //         case 'after':
                    //             item.operator = 'dateAfter'
                    //             break
                    //         case 'onOrAfter':
                    //             item.operator = 'dateOnOrAfter'
                    //             break
                    //         case 'before':
                    //             item.operator = 'dateBefore'
                    //             break
                    //         case 'onOrBefore':
                    //             item.operator = 'dateOnOrBefore'
                    //             break
                    //         default:
                    //             break
                    //     }
                    // }
                    if (!(item.value === null && item.operator !== 'isEmpty' && item.operator !== 'isNotEmpty')) {
                        predicates.push({
                            field: item.field,
                            operator: item.operator,
                            value: item.operator === 'isAnyOf' ? item?.value.toString() : item?.value,
                            isComplex: false,
                            ignoreAccent: true,
                            ignoreCase: true
                        })
                    }
                }
            }

            const model = filterModel && predicates.length > 0 ? [{
                isComplex: true,
                condition: filterModel.logicOperator,
                ignoreAccent: false,
                predicates: predicates
            }] : initialFilterModel
            setRows((old) => ({...old, filterModel: model && mapFilterModel ? mapFilterModel(model) : model}))
        }
    }

    const refreshGrid = () => {
        setRefresh(!refresh)
    }

    useEffect(() => {
        setIsLoading(true)
        axios({
            method: 'post',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: `${API}/${url}?skip=${(rows.page) * rows.pageSize}&take=${rows.pageSize}&requiresCount=${true}`,
            data: {
                sorted: rows.sortModel,
                where: rows.filterModel
            }
        }).then((response) => {
            setRows((prevState) => {
                return {
                    ...prevState,
                    data: mapFunction ? response.data.result.map(datum => mapFunction(datum)) : response.data.result,
                    total: response.data.count,
                }
            })
            setIsLoading(false)
        }).catch((error) => {
            setIsLoading(false)
            enqueueSnackbar(handleAxiosErrors(error), {
                variant: 'error',
            })
            logoutUnauthorizedUser(error, isLoggedIn, userLogout)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows.page, rows.pageSize, rows.sortModel, rows.filterModel, refresh])

    return {
        ...rows,
        isLoading,
        onPaginationModelChange,
        onSortModelChange,
        onFilterModelChange,
        refreshGrid
    }

}