import {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../../helpers/constants";
import useUserData from "../../../hooks/user/useUserData";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {useTranslation} from "react-i18next";
import {ALERTS} from "../../../locales/components/namespaces";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../../hooks/user/useUserAction";

const useRoles = () => {
    const {token, isLoggedIn} = useUserData()
    const {userLogout} = useUserAction()
    const {t} = useTranslation(ALERTS)
    const [loading, setLoading] = useState(false)
    const [roles, setRoles] = useState([])
    const [updateRole, setUpdateRole] = useState(false)

    useEffect(() => {
        setLoading(true)
        axios({
            method: 'get',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: `${API}/role/list`,
        }).then((response) => {
            setRoles(response.data)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            enqueueSnackbar(handleAxiosErrors(error, t('Failed to get roles list.')), {
                    variant: 'error'
                }
            )
            logoutUnauthorizedUser(error, isLoggedIn, userLogout)
        })
    }, [updateRole])

    const store = (newRow) => {
        axios({
            method: 'post',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: `${API}/role/store`,
            data: newRow
        }).then(response => {
            setUpdateRole(!updateRole)
            enqueueSnackbar(t('Success Store'), {
                    autoHideDuration: 3000,
                    variant: 'success'
                }
            )
        }).catch(error => {
            setUpdateRole(!updateRole)
            enqueueSnackbar(handleAxiosErrors(error, t('Failed to store role!')), {
                    variant: 'error'
                }
            )
            logoutUnauthorizedUser(error, isLoggedIn, userLogout)
        })
    }

    const update = (newRow, oldRow) => {
        axios({
            method: 'post',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: `${API}/role/update`,
            data: newRow
        }).then(response => {
            setUpdateRole(!updateRole)
            enqueueSnackbar(t('Success Store'), {
                    autoHideDuration: 3000,
                    variant: 'success'
                }
            )
        }).catch(error => {
            setUpdateRole(!updateRole)
            enqueueSnackbar(handleAxiosErrors(error, t('Failed to update role!')), {
                    variant: 'error'
                }
            )
            logoutUnauthorizedUser(error, isLoggedIn, userLogout)
        })
    }

    const remove = (id) => {
        axios({
            method: 'post',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: `${API}/role/remove`,
            data: {
                id: id
            }
        }).then(response => {
            setUpdateRole(!updateRole)
            enqueueSnackbar(t('Success Store'), {
                    autoHideDuration: 3000,
                    variant: 'success'
                }
            )
        }).catch(error => {
            enqueueSnackbar(handleAxiosErrors(error, t('Failed to delete role!')), {
                    variant: 'error',
                    autoHideDuration: null
                }
            )
            logoutUnauthorizedUser(error, isLoggedIn, userLogout)
        })
    }

    return {
        loading,
        roles,
        store,
        update,
        remove
    }
}

export default useRoles