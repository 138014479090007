import * as Namespaces from './namespaces'
import React from 'react'

const locales = {
    [Namespaces.PARAMETERS]: {
        el: {
            'Add': 'Προσθήκη',
            'Actions': 'Ενέργειες',
            'Code': 'Κωδικός',
            'Description': 'Περιγραφή',
            'Sort Description': 'Σύντομη περιγραφή',
            'Description EL': 'Περιγραφή EL',
            'Description EN': 'Περιγραφή EN',
            'Customer Status': 'Κατάσταση Πελάτη',
            'Countries': 'Χώρες',
            'Nationalities': 'Εθνικότητες',
            'Documents': 'Έγγραφα',
            'Groups': 'Ομάδες',
            'Resident of Greece': 'Κάτοικος Ελλάδας',
            'Loan Status': 'Κατάσταση Δανείου',
            'Loan Type': 'Σκοπός δανείου',
            'Documents Associations': 'Συσχέτιση Εγγράφων',
            'Documents Types': 'Τύποι Εγγράφων',
            'Default': 'Προκαθορισμένο',
            'Roles': 'Ρόλοι',
            'Role': 'Ρόλος',
            'Right': 'Δικαίωμα',
            'Attribute': 'Χαρακτηριστικό',
            'View Access': 'Πρόσβαση σε προβολή',
            'Edit Access': 'Πρόσβαση σε επεξεργασία',
            'Rights and roles': 'Δικαιώματα και ρόλοι',
            'Rights': 'Δικαιώματα',
            'Origin': 'Προέλευση',
            'Required': 'Υποχρεωτικό',
            'Document Code': 'Κωδικός εγγράφου',
            'Original File Name': 'Αρχικό όνομα αρχείου',
            'File Name': 'Όνομα αρχείου',
            'File Type': 'Τύπος αρχείου',
            'Active': 'Ενεργό',
            'Bank Spread': 'Τραπεζικό spread',
            'Submit': 'Καταχώριση',
            'Uploaded by': "Ανέβηκε από",
            "Stable Interest": "Σταθερό Επιτόκιο",
            "Contribution": "Εισφορά",
            'Calculation variables': 'Μεταβλητές υπολογισμών',
            'Market Value of Real Estate (€)': 'Εμπορική αξία ακινήτου (€)',
            'Loan amount (€)': 'Ποσό δανείου (€)',
            'Characters must be more than 3': 'Οι χαρακτήρες πρέπει να είναι περισσότεροι από 3',
            'Characters must be less than 50': 'Οι χαρακτήρες πρέπει να είναι λιγότεροι από 50',
            'The field must be number': 'Το πεδίο πρέπει να είναι αριθμός',
            'Number of days for installment notification': 'Αριθμός ημερών για ειδοποίηση δόσης',
            "Loading... Please don't close the browser before the process ends.": "Φόρτωση... Μην κλείσετε το πρόγραμμα περιήγησης πριν τελειώσει η διαδικασία."
        },
        en: {}
    },
}

export default locales