// material-ui
import {Box, Grid, Stack} from '@mui/material';

// assets
import Logo from "../../components/logo";
import AuthCard from "./authCard";
import Localization from "../../layout/mainLayout/header/headerContent/localization";
import React from "react";
import {useTheme} from "@mui/material/styles";
import {ThemeMode} from "../../config";
import useConfig from "../../hooks/useConfig";


// ==============================|| AUTHENTICATION - WRAPPER ||============================== //

const AuthWrapper = ({children}) => {
	const theme = useTheme();
	const {mode} = useConfig();
	return (
		<Box sx={{minHeight: '100vh', bgcolor: mode === ThemeMode.LIGHT ? theme.palette.primary.ims : 'inherit'}}>
			<div
				style={{
					position: 'absolute',
					right: 20,
					top: 20,
					zIndex: 1
				}}
			>
				<Localization/>
			</div>
			<Grid
				container
				direction="column"
				justifyContent="flex-end"
				sx={{
					minHeight: '96vh'
				}}
			>
				<Grid item xs={12}>
					<Grid
						item
						xs={12}
						container
						justifyContent="center"
						alignItems="start"
						sx={{
							minHeight: {
								xs: 'calc(100vh - 140px)',
								sm: 'calc(100vh - 100px)'
							}
						}}
					>
						<Grid item>
							<Stack><Logo/></Stack>
							<AuthCard>{children}</AuthCard>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}

export default AuthWrapper;
