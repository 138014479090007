import * as React from "react";

const LogoTeiresias = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={200}
		height={50}
		id="svg3505"
		{...props}
	>
		<defs id="defs3507"/>
		<sodipodi
			id="base"
			pagecolor="#ffffff"
			bordercolor="#666666"
			borderopacity={1}
			showgrid="false"
		/>
		<g
			id="layer1"
			transform="translate(0,-1002.3622)"
		>
			<g
				style={{
					fontSize: 36,
					fontStyle: "normal",
					fontVariant: "normal",
					fontWeight: "normal",
					fontStretch: "normal",
					lineHeight: "125%",
					letterSpacing: 0,
					wordSpacing: 0,
					fill: "#000000",
					fillOpacity: 1,
					stroke: "none",
					fontFamily: "Liberation Sans",
					InkscapeFontSpecification: "Liberation Sans",
				}}
				id="text3513"
			>
				<path
					d="m 4.0148361,1013.3071 0,-5.1953 27.9999999,0 0,5.1953 -10.800781,0 0,35.5196 -6.398438,0 0,-35.5196 -10.8007809,0"
					style={{
						fontSize: 56,
						fontFamily: "Tiresias PCfont",
						InkscapeFontSpecification: "Tiresias PCfont",
					}}
					id="path3052"
				/>
				<path
					d="m 43.882024,1013.7173 -6.125,0 0,-8.0938 6.125,0 0,8.0938 m 0,5.1953 0,29.9141 -6.125,0 0,-29.9141 6.125,0"
					style={{
						fontSize: 56,
						fontFamily: "Tiresias PCfont",
						InkscapeFontSpecification: "Tiresias PCfont",
					}}
					id="path3054"
				/>
				<path
					d="m 59.522649,1027.5806 0,21.2461 -6.125,0 0,-29.9141 6.125,0 0,3.418 c 2.953111,-2.6068 5.268213,-3.9102 6.945312,-3.9102 1.184877,0 2.442688,0.4102 3.773438,1.2305 l -1.96875,5.3594 c -1.020852,-0.3099 -2.014341,-0.4649 -2.980469,-0.4649 -2.059911,0 -3.983086,1.0118 -5.769531,3.0352"
					style={{
						fontSize: 56,
						fontFamily: "Tiresias PCfont",
						InkscapeFontSpecification: "Tiresias PCfont",
					}}
					id="path3056"
				/>
				<path
					d="m 93.620305,1041.2524 2.296875,3.3633 c -3.208358,3.1901 -7.027364,4.7852 -11.457031,4.7852 -4.138033,0 -7.41928,-1.5951 -9.84375,-4.7852 -2.187504,-2.8802 -3.281253,-6.4622 -3.28125,-10.7461 -3e-6,-4.1015 1.066402,-7.6289 3.199218,-10.582 2.388013,-3.3177 5.55077,-4.9765 9.488282,-4.9766 4.976542,10e-5 8.631486,2.4883 10.964843,7.4649 1.203098,2.5703 1.80466,5.7969 1.804688,9.6797 l -18.921875,0 c 0.23697,6.3073 2.734363,9.4609 7.492187,9.4609 2.515606,0 5.268208,-1.2213 8.257813,-3.6641 m -15.640625,-10.2265 12.113281,0 c -0.565125,-5.3776 -2.58856,-8.0664 -6.070312,-8.0664 -3.463554,0 -5.477875,2.6888 -6.042969,8.0664"
					style={{
						fontSize: 56,
						fontFamily: "Tiresias PCfont",
						InkscapeFontSpecification: "Tiresias PCfont",
					}}
					id="path3058"
				/>
				<path
					d="m 101.19452,1046.3931 2.21485,-4.5938 c 3.09895,1.8412 6.14322,2.7617 9.13281,2.7617 3.35415,0 5.03123,-1.3216 5.03125,-3.9648 -2e-5,-1.2578 -0.69273,-2.2604 -2.07813,-3.0078 -0.76564,-0.4193 -2.09636,-0.9115 -3.99218,-1.4766 -2.00522,-0.6015 -3.40887,-1.0937 -4.21094,-1.4765 -3.2448,-1.5313 -4.86719,-4.0013 -4.86719,-7.4102 0,-3.4635 1.62239,-6.0156 4.86719,-7.6563 1.65884,-0.8385 3.80077,-1.2577 6.42578,-1.2578 2.73436,10e-5 5.78774,0.8933 9.16016,2.6797 l -1.94141,4.3477 c -2.60679,-1.513 -5.01304,-2.2695 -7.21875,-2.2696 -3.29949,10e-5 -4.94923,1.2852 -4.94922,3.8555 -10e-6,1.2578 0.8203,2.2787 2.46094,3.0625 0.29165,0.1276 1.64973,0.5834 4.07422,1.3672 2.98956,0.9479 5.04034,1.8685 6.15234,2.7617 1.71352,1.3672 2.57029,3.336 2.57031,5.9063 -2e-5,3.0442 -1.19403,5.3958 -3.58203,7.0547 -2.09637,1.4583 -4.70314,2.1875 -7.82031,2.1875 -3.88282,0 -7.69271,-0.9571 -11.42969,-2.8711"
					style={{
						fontSize: 56,
						fontFamily: "Tiresias PCfont",
						InkscapeFontSpecification: "Tiresias PCfont",
					}}
					id="path3060"
				/>
				<path
					d="m 137.72577,1013.7173 -6.125,0 0,-8.0938 6.125,0 0,8.0938 m 0,5.1953 0,29.9141 -6.125,0 0,-29.9141 6.125,0"
					style={{
						fontSize: 56,
						fontFamily: "Tiresias PCfont",
						InkscapeFontSpecification: "Tiresias PCfont",
					}}
					id="path3062"
				/>
				<path
					d="m 161.95234,1041.3071 0,-6.8086 c -0.78388,-0.164 -1.67711,-0.246 -2.67969,-0.2461 -5.94272,10e-5 -8.91407,1.9232 -8.91406,5.7696 -1e-5,1.8594 0.86587,3.2174 2.59765,4.0742 0.87499,0.4375 1.90494,0.6562 3.08985,0.6562 2.66144,0 4.63019,-1.1484 5.90625,-3.4453 m -11.42969,-17.0625 -1.96875,-4.3476 c 2.49739,-1.0937 5.35025,-1.6406 8.55859,-1.6407 2.51561,10e-5 4.45701,0.2735 5.82422,0.8204 2.13279,0.8568 3.59112,2.625 4.375,5.3046 0.49216,1.7136 0.73826,4.1016 0.73828,7.1641 l 0,17.2813 -6.09765,0 0,-1.9688 c -2.05992,1.6589 -4.37502,2.4883 -6.94532,2.4883 -3.09896,0 -5.56901,-0.8477 -7.41015,-2.543 -1.91407,-1.75 -2.8711,-4.1653 -2.8711,-7.2461 0,-4.4661 2.34245,-7.3645 7.02735,-8.6953 2.11457,-0.6015 4.47524,-0.9023 7.08203,-0.9023 1.13019,0 2.16925,0.036 3.11719,0.1093 -2e-5,-3.4635 -0.6745,-5.5416 -2.02344,-6.2343 -0.82033,-0.4193 -2.07814,-0.6289 -3.77344,-0.6289 -2.11459,0 -3.9922,0.3463 -5.63281,1.039"
					style={{
						fontSize: 56,
						fontFamily: "Tiresias PCfont",
						InkscapeFontSpecification: "Tiresias PCfont",
					}}
					id="path3064"
				/>
				<path
					d="m 174.03827,1046.3931 2.21485,-4.5938 c 3.09895,1.8412 6.14322,2.7617 9.13281,2.7617 3.35415,0 5.03123,-1.3216 5.03125,-3.9648 -2e-5,-1.2578 -0.69273,-2.2604 -2.07813,-3.0078 -0.76564,-0.4193 -2.09636,-0.9115 -3.99218,-1.4766 -2.00522,-0.6015 -3.40887,-1.0937 -4.21094,-1.4765 -3.2448,-1.5313 -4.86719,-4.0013 -4.86719,-7.4102 0,-3.4635 1.62239,-6.0156 4.86719,-7.6563 1.65884,-0.8385 3.80077,-1.2577 6.42578,-1.2578 2.73436,10e-5 5.78774,0.8933 9.16016,2.6797 l -1.94141,4.3477 c -2.60679,-1.513 -5.01304,-2.2695 -7.21875,-2.2696 -3.29949,10e-5 -4.94923,1.2852 -4.94922,3.8555 -1e-5,1.2578 0.8203,2.2787 2.46094,3.0625 0.29165,0.1276 1.64973,0.5834 4.07422,1.3672 2.98956,0.9479 5.04034,1.8685 6.15234,2.7617 1.71352,1.3672 2.57029,3.336 2.57031,5.9063 -2e-5,3.0442 -1.19403,5.3958 -3.58203,7.0547 -2.09637,1.4583 -4.70314,2.1875 -7.82031,2.1875 -3.88282,0 -7.69271,-0.9571 -11.42969,-2.8711"
					style={{
						fontSize: 56,
						fontFamily: "Tiresias PCfont",
						InkscapeFontSpecification: "Tiresias PCfont",
					}}
					id="path3066"
				/>
			</g>
		</g>
	</svg>
)

export default LogoTeiresias;
