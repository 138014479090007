import MainCard from "../../theme/mantis/components/mainCard";
import {
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    Stack,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {PAGE_TIRESIAS} from "../../locales/pages/namespaces";
import {ErrorMessage, FormikProvider} from "formik";
import useTiresias from "./useTiresias";
import ContentLoader from "../../components/contentLoader/contentLoader";
import {withPermissionHOC} from "../../modules/permission/components/permissionHOC/permissionHOC";

const Tiresias = () => {
    const {t} = useTranslation(PAGE_TIRESIAS)
    const {formik, isLoading} = useTiresias()

    return !isLoading ? (
        <FormikProvider value={formik}>
            <MainCard title={
                <Toolbar disableGutters variant={"dense"}>
                    <Typography variant="h4" gutterBottom>
                        {t('Tiresias')}
                    </Typography>
                </Toolbar>}>
                <Grid sx={{flexGrow: 1}} container spacing={2}>
                    <Grid item lg={6}>
                        <Grid container spacing={1} sx={{mr: 2}}>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={
                                            formik.touched["surname"] &&
                                            Boolean(formik.errors["surname"])
                                        }
                                    >
                                        <FormLabel htmlFor="surname" required>{t('Surname')}</FormLabel>
                                        <TextField
                                            id="surname"
                                            placeholder={t("Enter surname")}
                                            name={`surname`}
                                            value={formik.values.surname}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            required
                                            error={formik.touched.surname && Boolean(formik.errors.surname)}
                                            color={"grey"}
                                            size={'small'}
                                            fullWidth
                                            inputProps={{maxLength: 100}}
                                        />
                                        <FormHelperText>
                                            <ErrorMessage name={"surname"}/>
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={
                                            formik.touched["name"] &&
                                            Boolean(formik.errors["name"])
                                        }>
                                        <FormLabel htmlFor="name" required>{t('Name')}</FormLabel>
                                        <TextField
                                            name={`name`}
                                            placeholder={t("Enter name")}
                                            required
                                            error={formik.touched.name && Boolean(formik.errors.name)}
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            color={"grey"}
                                            size={'small'}
                                            fullWidth
                                            inputProps={{maxLength: 100}}
                                        />
                                        <FormHelperText>
                                            <ErrorMessage name={"name"}/>
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={
                                            formik.touched["fathername"] &&
                                            Boolean(formik.errors["fathername"])
                                        }>
                                        <FormLabel htmlFor="name" required>{t('Father name')}</FormLabel>
                                        <TextField
                                            name={`fathername`}
                                            placeholder={t("Enter father name")}
                                            required
                                            error={formik.touched.fathername && Boolean(formik.errors.fathername)}
                                            value={formik.values.fathername}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            color={"grey"}
                                            size={'small'}
                                            fullWidth
                                            inputProps={{maxLength: 100}}
                                        />
                                        <FormHelperText>
                                            <ErrorMessage name={"fathername"}/>
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={
                                            formik.touched["identityNo"] &&
                                            Boolean(formik.errors["identityNo"])
                                        }>
                                        <FormLabel htmlFor="name" required>{t('Identity No')}</FormLabel>
                                        <TextField
                                            name={`identityNo`}
                                            placeholder={t("Enter identity No")}
                                            required
                                            error={formik.touched.identityNo && Boolean(formik.errors.identityNo)}
                                            value={formik.values.identityNo}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            color={"grey"}
                                            size={'small'}
                                            fullWidth
                                            inputProps={{maxLength: 100}}
                                        />
                                        <FormHelperText>
                                            <ErrorMessage name={"identityNo"}/>
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={
                                            formik.touched["mobile"] &&
                                            Boolean(formik.errors["mobile"])
                                        }>
                                        <FormLabel htmlFor="name" required>{t('Mobile phone')}</FormLabel>
                                        <TextField
                                            name={`mobile`}
                                            placeholder={t("Enter mobile phone")}
                                            required
                                            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                                            value={formik.values.mobile}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            color={"grey"}
                                            size={'small'}
                                            fullWidth
                                            inputProps={{maxLength: 100}}
                                        />
                                        <FormHelperText>
                                            <ErrorMessage name={"mobile"}/>
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={
                                            formik.touched["vat"] &&
                                            Boolean(formik.errors["vat"])
                                        }>
                                        <FormLabel htmlFor="name" required>{t('VAT')}</FormLabel>
                                        <TextField
                                            name={`vat`}
                                            placeholder={t("Enter VAT")}
                                            required
                                            error={formik.touched.vat && Boolean(formik.errors.vat)}
                                            value={formik.values.vat}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            color={"grey"}
                                            size={'small'}
                                            fullWidth
                                            inputProps={{maxLength: 100}}
                                        />
                                        <FormHelperText>
                                            <ErrorMessage name={"vat"}/>
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={
                                            formik.touched["eMail"] &&
                                            Boolean(formik.errors["eMail"])
                                        }
                                    >
                                        <FormLabel htmlFor="eMail" required>{t('Email')}</FormLabel>
                                        <TextField
                                            placeholder={t("Enter email")}
                                            type={'email'}
                                            value={formik.values.eMail}
                                            name={`eMail`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            fullWidth
                                            color={"grey"}
                                            size={'small'}
                                            required
                                            error={formik.touched.eMail && Boolean(formik.errors.eMail)}
                                            inputProps={{
                                                maxLength: 150,
                                                autoComplete: 'off',
                                            }}
                                        />
                                        <FormHelperText>
                                            <ErrorMessage name={"eMail"}/>
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={
                                            formik.touched["govAuthVerificationCode"] &&
                                            Boolean(formik.errors["govAuthVerificationCode"])
                                        }>
                                        <FormLabel htmlFor="govAuthVerificationCode"
                                                   required>{t('Responsible declaration code')}</FormLabel>
                                        <TextField
                                            name={`govAuthVerificationCode`}
                                            placeholder={t("Enter declaration code")}
                                            required
                                            error={formik.touched.govAuthVerificationCode && Boolean(formik.errors.govAuthVerificationCode)}
                                            value={formik.values.govAuthVerificationCode}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            color={"grey"}
                                            size={'small'}
                                            fullWidth
                                            inputProps={{maxLength: 100}}
                                        />
                                        <FormHelperText>
                                            <ErrorMessage name={"govAuthVerificationCode"}/>
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Button
                            size={"small"}
                            variant={"contained"}
                            sx={{float: "right", mb: 2, mt: 2}}
                            onClick={formik.submitForm}
                        >
                            {t('Check')}
                        </Button>
                    </Grid>
                </Grid>
            </MainCard>
        </FormikProvider>
    ) : (
        <ContentLoader
            withBackDrop={true}
            useCircularLoader={true}
            loading={isLoading}
            message={t("Loading... Please don't close the browser before the process ends.")}
        />
    )

}
export default withPermissionHOC(Tiresias, ['access_tiresias'])