import {forwardRef, useEffect} from 'react';
import {Link} from 'react-router-dom';

// material-ui
import {useTheme} from '@mui/material/styles';
import {Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery} from '@mui/material';
import useConfig from "../../../../../hooks/useConfig";
import {MenuOrientation, ThemeMode} from "../../../../../config";
import Dot from "../../../../../components/@extended/dot";
import useMenuData from "../../../../../../../hooks/menu/useMenuData";
import useMenuAction from "../../../../../../../hooks/menu/useMenuAction";
import PermissionHOC from "../../../../../../../modules/permission/components/permissionHOC/permissionHOC";

// project import

// import { dispatch, useSelector } from 'store';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({item, level, onClickAction}) => {
    const theme = useTheme();
    const {menuOrientation} = useConfig();
    const downLG = useMediaQuery(theme.breakpoints.down('lg'));
    const {drawerOpen, openItem} = useMenuData()
    const {setMenuActiveItem, setDrawerOpen,} = useMenuAction()

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = {
        component: forwardRef((props, ref) => <Link {...props} to={item.url} target={itemTarget} ref={ref}/>)
    };
    if (item?.external) {
        listItemProps = {component: 'a', href: item.url, target: itemTarget};
    }

    const Icon = item.icon;
    const itemIcon = item.icon ? <Icon style={{fontSize: drawerOpen ? '1rem' : '1.25rem'}}/> : false;

    const isSelected = openItem.findIndex((id) => id === item.id) > -1;

    const pathname = document.location.pathname;

    // active menu item on page load
    useEffect(() => {
        if (pathname && pathname.includes('product-details')) {
            if (item.url && item.url.includes('product-details')) {
                setMenuActiveItem({openItem: [item.id]});
            }
        }

        if (pathname && pathname.includes('kanban')) {
            if (item.url && item.url.includes('kanban')) {
                setMenuActiveItem({openItem: [item.id]});
            }
        }

        if (pathname.includes(item.url)) {
            setMenuActiveItem({openItem: [item.id]});
        }
        // eslint-disable-next-line
    }, [pathname]);

    const textColor = theme.palette.mode === ThemeMode.DARK ? 'grey.400' : 'text.primary';
    const iconSelectedColor = theme.palette.mode === ThemeMode.DARK && drawerOpen ? 'text.primary' : 'primary.main';

    const navItem = (
        <>
            {menuOrientation === MenuOrientation.VERTICAL || downLG ? (
                <ListItemButton
                    {...listItemProps}
                    disabled={item.disabled}
                    selected={isSelected}
                    sx={{
                        zIndex: 1201,
                        pl: drawerOpen ? `${level * 28}px` : 1.5,
                        py: !drawerOpen && level === 1 ? 1.25 : 1,
                        ...(drawerOpen && {
                            '&:hover': {
                                bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'primary.lighter'
                            },
                            '&.Mui-selected': {
                                bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'primary.lighter',
                                borderRight: `2px solid ${theme.palette.primary.main}`,
                                color: iconSelectedColor,
                                '&:hover': {
                                    color: iconSelectedColor,
                                    bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'primary.lighter'
                                }
                            }
                        }),
                        ...(!drawerOpen && {
                            '&:hover': {
                                bgcolor: 'transparent'
                            },
                            '&.Mui-selected': {
                                '&:hover': {
                                    bgcolor: 'transparent'
                                },
                                bgcolor: 'transparent'
                            }
                        })
                    }}
                    onClick={() => {
                        if (downLG) setDrawerOpen(false);
                        if (onClickAction) onClickAction()
                    }}
                    title={item.hoverMessage}
                >
                    {itemIcon && (
                        <ListItemIcon
                            sx={{
                                minWidth: 28,
                                color: isSelected ? iconSelectedColor : textColor,
                                ...(!drawerOpen && {
                                    borderRadius: 1.5,
                                    width: 36,
                                    height: 36,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '&:hover': {
                                        bgcolor: theme.palette.mode === ThemeMode.DARK ? 'secondary.light' : 'secondary.lighter'
                                    }
                                }),
                                ...(!drawerOpen &&
                                    isSelected && {
                                        bgcolor: theme.palette.mode === ThemeMode.DARK ? 'primary.900' : 'primary.lighter',
                                        '&:hover': {
                                            bgcolor: theme.palette.mode === ThemeMode.DARK ? 'primary.darker' : 'primary.lighter'
                                        }
                                    })
                            }}
                        >
                            {itemIcon}
                        </ListItemIcon>
                    )}
                    {(drawerOpen || (!drawerOpen && level !== 1)) && (
                        <ListItemText
                            primary={
                                <Typography variant="h6"
											sx={{color: isSelected ? iconSelectedColor : textColor, textWrap: 'wrap'}}>
									{item.title}
								</Typography>
                            }
                        />
                    )}
                    {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                        <Chip
                            color={item.chip.color}
                            variant={item.chip.variant}
                            size={item.chip.size}
                            label={item.chip.label}
                            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                        />
                    )}
                </ListItemButton>
            ) : (
                <ListItemButton
                    {...listItemProps}
                    disabled={item.disabled}
                    selected={isSelected}
                    sx={{
                        zIndex: 1201,
                        ...(drawerOpen && {
                            '&:hover': {
                                bgcolor: 'transparent'
                            },
                            '&.Mui-selected': {
                                bgcolor: 'transparent',
                                color: iconSelectedColor,
                                '&:hover': {
                                    color: iconSelectedColor,
                                    bgcolor: 'transparent'
                                }
                            }
                        }),
                        ...(!drawerOpen && {
                            '&:hover': {
                                bgcolor: 'transparent'
                            },
                            '&.Mui-selected': {
                                '&:hover': {
                                    bgcolor: 'transparent'
                                },
                                bgcolor: 'transparent'
                            }
                        })
                    }}
                    onClick={() => {
                        if (onClickAction) onClickAction()
                    }}
                    title={item.hoverMessage}
                >
                    {itemIcon && (
                        <ListItemIcon
                            sx={{
                                minWidth: 36,
                                ...(!drawerOpen && {
                                    borderRadius: 1.5,
                                    width: 36,
                                    height: 36,
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    '&:hover': {
                                        bgcolor: 'transparent'
                                    }
                                }),
                                ...(!drawerOpen &&
                                    isSelected && {
                                        bgcolor: 'transparent',
                                        '&:hover': {
                                            bgcolor: 'transparent'
                                        }
                                    })
                            }}
                        >
                            {itemIcon}
                        </ListItemIcon>
                    )}

                    {!itemIcon && (
                        <ListItemIcon
                            sx={{
                                color: isSelected ? 'primary.main' : 'secondary.main',
                                ...(!drawerOpen && {
                                    borderRadius: 1.5,
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    '&:hover': {
                                        bgcolor: 'transparent'
                                    }
                                }),
                                ...(!drawerOpen &&
                                    isSelected && {
                                        bgcolor: 'transparent',
                                        '&:hover': {
                                            bgcolor: 'transparent'
                                        }
                                    })
                            }}
                        >
                            <Dot size={4} color={isSelected ? 'primary' : 'secondary'}/>
                        </ListItemIcon>
                    )}
                    <ListItemText
                        primary={
							<Typography variant="h6" color="inherit" sx={{textWrap: 'wrap'}}>
								{item.title}
							</Typography>
                        }
                    />
                    {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                        <Chip
                            color={item.chip.color}
                            variant={item.chip.variant}
                            size={item.chip.size}
                            label={item.chip.label}
                            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                        />
                    )}
                </ListItemButton>
            )}
        </>
    )

    return item.permissions && item.permissions.length > 0 ? (
        <PermissionHOC
            requiredPermissions={[...item.permissions]}>
            {navItem}
        </PermissionHOC>
    ) : (
        <>{navItem}</>
    )
};
//
// NavItem.propTypes = {
//     item: PropTypes.object,
//     level: PropTypes.number
// };

export default NavItem;
