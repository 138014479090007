// ==============================|| OVERRIDES - DATA GRID ||============================== //

export default function DataGrid(theme) {
    console.log(theme.palette)
    return {
        MuiDataGrid: {
            styleOverrides: {
                cell: {
                    borderBottom: `2px solid ${theme.palette.primary['lighter']}`,
                },
                columnHeaders: {
                    background: theme.palette.grey['100']
                },
                columnHeader: {
                    borderColor: theme.palette.divider
                },

            },
        },
    };
}
