// material-ui
//import {useTheme} from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 * import { ThemeMode } from 'config';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //
import * as React from "react";
const LogoIcon = (props) => (
    <svg
        baseProfile="tiny"
        xmlns="http://www.w3.org/2000/svg"
        width={129}
        height={129}
        viewBox="0 0 129 129"
        xmlSpace="preserve"
        {...props}
    >
        <switch>
            <foreignObject
                requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/"
                x={0}
                y={0}
                width={1}
                height={1}
            />
            <g>
                <path fill="#485776" d="M0 0h129v129H0z" />
                <path
                    d="M71.62 74.601 50.655 95.44a1.955 1.955 0 0 1-2.755-.006L27.053 74.473a1.956 1.956 0 0 1 .011-2.763l20.964-20.839a1.941 1.941 0 0 1 2.755.008l20.844 20.959a1.957 1.957 0 0 1-.007 2.763m13.704-3.879L51.67 36.873a3.137 3.137 0 0 0-4.45-.012L13.37 70.519a3.136 3.136 0 0 0-.011 4.449l33.656 33.849a3.141 3.141 0 0 0 4.448.013l33.849-33.657a3.14 3.14 0 0 0 .012-4.451m7.438-49.58c-.268 0-.52.106-.711.296L71.149 42.339a1.002 1.002 0 0 0 0 1.425L92.05 64.666a1.01 1.01 0 0 0 1.422 0l20.905-20.902a1.005 1.005 0 0 0 0-1.425L93.473 21.438a.996.996 0 0 0-.708-.296h-.003zm0 45.702a2.887 2.887 0 0 1-2.042-.84L69.814 45.101a2.886 2.886 0 0 1-.851-2.047c0-.773.304-1.503.851-2.05L90.72 20.105a2.867 2.867 0 0 1 2.042-.851h.006a2.87 2.87 0 0 1 2.043.853l20.904 20.897a2.903 2.903 0 0 1 0 4.097l-20.91 20.901a2.875 2.875 0 0 1-2.043.842"
                    fill="#FFF"
                />
            </g>
        </switch>
    </svg>
);
export default LogoIcon;
