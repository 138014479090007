//@flow
import * as React from 'react';
import {Backdrop, Skeleton} from "@mui/material";
import NotFound from "../../pages/notFound/notFound";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";
import {blue} from "@mui/material/colors";

type Props = {
    loading: boolean,
    notFoundWhenNotLoading?: boolean,
    notFoundComponent?: React.Node,
    useCircularLoader?: boolean,
    message?: string,
    withBackDrop?: boolean
}

const Loader = ({useCircularLoader, message}) => {
    const color = blue[200]
    return (
        <Box sx={{width: "100%", py: 2}}>
            {useCircularLoader ? (
                <Box sx={{
                    width: "100%",
                    display: 'flex',
                    minHeight: 100,
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: 'column'
                }}>
                    {message && (
                        <Typography
                            variant="button"
                            color={color}
                        >
                            {message}
                        </Typography>
                    )}
                    <CircularProgress
                        sx={{
                            color: color
                        }}
                    />
                </Box>
            ) : (
                <React.Fragment>
                    <Skeleton/>
                    <Skeleton animation="wave"/>
                    <Skeleton animation={false}/>
                </React.Fragment>
            )}
        </Box>
    )
}

const ContentLoader = (props: Props) => {
    const {loading, notFoundWhenNotLoading, notFoundComponent, useCircularLoader, message, withBackDrop} = props
    if (!loading && !notFoundWhenNotLoading) return null
    if (!loading && notFoundWhenNotLoading) return notFoundComponent ?? <NotFound/>

    return withBackDrop ? (
        <Backdrop
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            open={loading}
        >
            <Loader
                useCircularLoader={useCircularLoader}
                message={message}
            />
        </Backdrop>
    ) : (
        <Loader
            useCircularLoader={useCircularLoader}
            message={message}
        />
    )
}

export default ContentLoader

// //@flow
// import * as React from 'react';
// import {Skeleton} from "@mui/material";
// import NotFound from "../../pages/notFound/notFound";
// import Box from "@mui/material/Box";
// import CircularProgress from '@mui/material/CircularProgress';
// import Typography from "@mui/material/Typography";
// import {useTranslation} from "react-i18next";
// import {ALERTS} from "../../locales/components/namespaces";
//
// type Props = {
//     loading: boolean,
//     notFoundWhenNotLoading?: boolean,
//     notFoundComponent?: React.Node,
//     useCircularLoader?: boolean
// }
//
// const ContentLoader = (props: Props) => {
//     const {t} = useTranslation(ALERTS)
//     const {loading, notFoundWhenNotLoading, notFoundComponent, useCircularLoader} = props
//     if (!loading && !notFoundWhenNotLoading) return null
//     if (!loading && notFoundWhenNotLoading) return notFoundComponent ?? <NotFound/>
//     return (
//         <Box sx={{width: "100%", py: 2, mb: 20, mt: 20}}>
//             {useCircularLoader ? (
//                 <Box sx={{
//                     width: "100%",
//                     display: 'flex', minHeight: 100, justifyContent: "center", alignItems: "center"
//                 }}>
//                     <CircularProgress/>
//                 </Box>
//             ) : (
//                 <React.Fragment>
//                     <Typography variant={"h5"} textAlign={'center'} fontStyle={'italic'}>{t('Loading...')}</Typography>
//                     <Skeleton/>
//                     <Skeleton animation="wave"/>
//                     <Skeleton animation={false}/>
//                     <Skeleton animation="wave"/>
//                     <Skeleton animation={false}/>
//                     <Skeleton animation="wave"/>
//                 </React.Fragment>
//             )}
//         </Box>
//     )
// }
//
// export default ContentLoader