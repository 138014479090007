import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {PAGE_TIRESIAS} from "../../locales/pages/namespaces";
import {useMuiGridServerSide} from "../../hooks/useMuiGridServerSide";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import moment from "moment";
import {Box,Divider, Grid, Toolbar, Typography} from "@mui/material";
import MainCard from "../../theme/mantis/components/mainCard";
import DataGridWrapper from "../../components/dataGridWrapper/dataGridWrapper";
import {muiGridLocales} from "../../helpers/muiGridLocales";
import {CustomPagination} from "../../components/dataGridCustom/customPagination";
import {withPermissionHOC} from "../../modules/permission/components/permissionHOC/permissionHOC";

const History = () => {
    const {t} = useTranslation(PAGE_TIRESIAS)
    const url = `logs`

    const {
        isLoading,
        data,
        total,
        page,
        pageSize,
        onPaginationModelChange,
        onSortModelChange,
        onFilterModelChange,
        refreshGrid
    } = useMuiGridServerSide({
        url,
        initialPageSize: 50,
        initialSortModel: [{name: 'id', direction: 'descending'}]
    })

    const columns = useMemo(() => [
        {
            field: 'LoginName',
            headerName: t('Login Name'),
            minWidth: 160, flex: 1,
        },
        {
            field: 'PersonName',
            headerName: t('Person Name'),
            minWidth: 160, flex: 1,
        },
        {
            field: 'VatRegNo',
            headerName: t('Vat Reg No'),
            minWidth: 160, flex: 1,
        },
        {
            field: 'SubmitDt',
            headerName: t('Submit Date'),
            minWidth: 160, flex: 1,
            type: 'date',
            valueFormatter: params => params?.value ? moment(params?.value).format("DD-MM-YYYY , HH:mm") : '',
            aggregable: false,
        }
    ], [data, refreshGrid])

    return (
        <Box>
            <MainCard title={<Toolbar disableGutters variant={"dense"}>
                <Grid container justifyContent="space-between" alignItems="center"
                      sx={{position: 'relative', zIndex: 5}}>
                    <Grid item>
                        <Typography variant="h4" gutterBottom>
                            {t('History')}
                        </Typography>
                    </Grid>
                </Grid>
            </Toolbar>
            }>
                <Divider sx={{mb: 2}} light/>
                <DataGridWrapper autoHeight>
                    <DataGridPremium
                        sx={{
                            bgcolor: 'background.paper', ".MuiDataGrid-aggregationColumnHeaderLabel": {
                                display: "none"
                            },
                            maxHeight: 650
                        }}
                        columns={columns}
                        density={'compact'}
                        rows={data}
                        pagination
                        paginationModel={{page, pageSize}}
                        paginationMode='server'
                        onPaginationModelChange={onPaginationModelChange}
                        rowCount={Number(total)}
                        rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
                        sortingMode="server"
                        onSortModelChange={(sortModel) => onSortModelChange(sortModel)}
                        filterMode='server'
                        onFilterModelChange={onFilterModelChange}
                        loading={isLoading}
                        localeText={muiGridLocales(t)}
                        slots={{
                            pagination: CustomPagination,
                        }}
                    />
                </DataGridWrapper>
            </MainCard>
        </Box>
    )
}

export default withPermissionHOC(History, ['access_history'])