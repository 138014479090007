// @flow
import React from 'react';
import Box from "@mui/material/Box";
import PermissionHOC from "../../modules/permission/components/permissionHOC/permissionHOC";
import Tiresias from "../tiresias/tiresias";

const Home = () => {
    return (
        <Box>
            <PermissionHOC requiredPermissions={['access_tiresias']}>
                <Tiresias/>
            </PermissionHOC>
        </Box>
    )
}

export default Home
