import axios from "axios";
import {API} from "../../../helpers/constants";
import {useEffect, useState} from "react";
import useUserData from "../../../hooks/user/useUserData";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {useTranslation} from "react-i18next";
import {ALERTS} from "../../../locales/components/namespaces";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../../hooks/user/useUserAction";

const useUser = ({id}) => {
    const {token, isLoggedIn} = useUserData()
    const {userLogout} = useUserAction()
    const {t} = useTranslation(ALERTS)
    const [user, setUser] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        if (id) {
            axios({
                method: 'post',
                data: {UserId: id},
                headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
                url: `${API}/user/edit/data`,
            }).then(response => {
                let data = response.data.user
                setUser({
                    isActive: data.isActive === '1',
                    isPartner: data.isPartner === '1',
                    LoginName: data.LoginName,
                    username: data.username,
                    password: data.password,
                    confirmPassword: data.confirmPassword,
                    email: data.email,
                    CurrencyId: data.CurrencyId,
                    NationalityId: data.NationalityId,
                    LangId: data.LangId,
                    CountryId: data.CountryId,
                    mobilePhone: data.mobilePhone,
                    phone: data.phone
                })
                setLoading(false)
            }).catch(error => {
                setLoading(false)
                enqueueSnackbar(handleAxiosErrors(error, t('Failed to get user details.'), {
                        variant: 'error',
                        autoHideDuration: null
                    }
                ))
                logoutUnauthorizedUser(error, isLoggedIn, userLogout)
            })
        }
    }, [id, isLoggedIn, t, token, userLogout])

    return {
        loading,
        user
    }
}

export default useUser
