import * as React from "react";
const ErgoLogoIcon = (props) => (
    <svg
        baseProfile="tiny"
        xmlns="http://www.w3.org/2000/svg"
        width={129}
        height={129}
        style={{
            shapeRendering: "geometricPrecision",
            textRendering: "geometricPrecision",
            imageRendering: "optimizeQuality",
            fillRule: "evenodd",
            clipRule: "evenodd",
        }}
        viewBox="0 0 129 129"
        xmlSpace="preserve"
        {...props}
    >
        <path
            style={{
                opacity: 0.951,
            }}
            fill="#fff"
            d="M1.5 23.5q21.523-.5 43 1a23.8 23.8 0 0 1 1 9h-44z"
        />
        <path
            style={{
                opacity: 0.933,
            }}
            fill="#fff"
            d="M54.5 23.5a32.4 32.4 0 0 1 8 .5A119 119 0 0 0 75 37.5q-.7 16.252-11 4-.75 11.99-.5 24 16.507-.25 33 .5 1.416 4.079 1 8.5h-43z"
        />
        <path
            style={{
                opacity: 0.871,
            }}
            fill="#fff"
            d="M1.5 43.5q12.01-.25 24 .5l1.5 1.5q.747 4.473.5 9h-26z"
        />
        <path
            style={{
                opacity: 0.944,
            }}
            fill="#fff"
            d="M1.5 64.5h44a23.8 23.8 0 0 1-1 9 463 463 0 0 1-43 1z"
        />
    </svg>
);
export default ErgoLogoIcon;