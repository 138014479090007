import {actionCreator} from '../helpers/actionCreator'
import * as ActionTypes from './types'

export * from './currentUser/actions'

export const getActiveExhibitions = actionCreator(ActionTypes.GET_ACTIVE_EXHIBITIONS)
export const getActiveExhibitionsSucceeded = actionCreator(ActionTypes.GET_ACTIVE_EXHIBITIONS_SUCCEEDED)
export const getActiveExhibitionsFailed = actionCreator(ActionTypes.GET_ACTIVE_EXHIBITIONS_FAILED)

export const getExhibitionDetails = actionCreator(ActionTypes.GET_EXHIBITION_DETAILS)
export const getExhibitionDetailsSucceeded = actionCreator(ActionTypes.GET_EXHIBITION_DETAILS_SUCCEEDED)
export const getExhibitionDetailsFailed = actionCreator(ActionTypes.GET_EXHIBITION_DETAILS_FAILED)

export const createVisitor = actionCreator(ActionTypes.CREATE_VISITOR)
export const createVisitorSucceeded = actionCreator(ActionTypes.CREATE_VISITOR_SUCCEEDED)
export const createVisitorFailed = actionCreator(ActionTypes.CREATE_VISITOR_FAILED)

export const getCoorganizationInterests = actionCreator(ActionTypes.GET_COORGANIZATION_INTERESTS)
export const getCoorganizationInterestsSucceeded = actionCreator(ActionTypes.GET_COORGANIZATION_INTERESTS_SUCCEEDED)
export const getCoorganizationInterestsFailed = actionCreator(ActionTypes.GET_COORGANIZATION_INTERESTS_FAILED)

export const getExhibitionInterests = actionCreator(ActionTypes.GET_EXHIBITION_INTERESTS)
export const getExhibitionInterestsSucceeded = actionCreator(ActionTypes.GET_EXHIBITION_INTERESTS_SUCCEEDED)
export const getExhibitionInterestsFailed = actionCreator(ActionTypes.GET_EXHIBITION_INTERESTS_FAILED)

export const getCountries = actionCreator(ActionTypes.GET_COUNTRIES)
export const getCountriesSucceeded = actionCreator(ActionTypes.GET_COUNTRIES_SUCCEEDED)
export const getCountriesFailed = actionCreator(ActionTypes.GET_COUNTRIES_FAILED)

export const setUserAlterPassword = actionCreator(ActionTypes.SET_USER_ALTER_PASSWORD)

export const setMenu = actionCreator(ActionTypes.SET_MENU)
export const setMenuActiveItem = actionCreator(ActionTypes.SET_MENU_ACTIVE_ITEM)
export const setMenuActiveId = actionCreator(ActionTypes.SET_MENU_ACTIVE_ID)
export const setMenuActiveComponent = actionCreator(ActionTypes.SET_MENU_ACTIVE_COMPONENT)
export const setDrawerOpen = actionCreator(ActionTypes.SET_DRAWER_OPEN)
export const setDrawerComponentOpen = actionCreator(ActionTypes.SET_DRAWER_COMPONENT_OPEN)

export const googleLogin = actionCreator(ActionTypes.GOOGLE_LOGIN)
export const googleLoginSucceeded = actionCreator(ActionTypes.GOOGLE_LOGIN_SUCCEEDED)
export const googleLoginFailed = actionCreator(ActionTypes.GOOGLE_LOGIN_FAILED)