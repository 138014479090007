import * as React from 'react';
import {useEffect, useLayoutEffect, useMemo, useState} from 'react';

// material-ui
import {useTheme} from '@mui/material/styles';
import {Box, Typography, useMediaQuery} from '@mui/material';
import {
    DashboardOutlined,
    FiberManualRecordOutlined,
    Groups2Outlined,
    HomeOutlined,
    PeopleOutlined,
    SettingsOutlined,
} from '@mui/icons-material';
import HistoryIcon from '@mui/icons-material/History';

// project import
import NavGroup from './navGroup';
import useConfig from "../../../../../hooks/useConfig";
import {HORIZONTAL_MAX_ITEM, MenuOrientation} from "../../../../../config";
import {Menu} from "../../../../../menu-items/dashboard";
import {getRouteUrl} from "../../../../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_TIRESIAS, ROUTE_PAGE_HISTORY} from "../../../../../../../routers/routes";

import {ROUTE_PAGE_USERS_INDEX} from "../../../../../../../modules/users/routes/routes";
import {useTranslation} from "react-i18next";
import {NAVIGATION} from "../../../../../../../locales/components/namespaces";
import useUserAction from "../../../../../../../hooks/user/useUserAction";
import useMenuData from "../../../../../../../hooks/menu/useMenuData";
import NavItem from "./navItem";
import {LogoutOutlined} from "@ant-design/icons";
import {
    ROUTE_PAGE_PARAMETERS_RIGHTS,
    ROUTE_PAGE_PARAMETERS_RIGHTS_ROLES,
    ROUTE_PAGE_PARAMETERS_ROLES
} from "../../../../../../../modules/parameters/routes/routes";

const Navigation = () => {
    const theme = useTheme();
    const downLG = useMediaQuery(theme.breakpoints.down('lg'));
    const {menuOrientation} = useConfig();
    const {drawerOpen} = useMenuData()
    const [selectedItems, setSelectedItems] = useState('');
    const [selectedLevel, setSelectedLevel] = useState(0);
    const [menuItems, setMenuItems] = useState({items: []});
    const {t} = useTranslation(NAVIGATION)
    const {userLogout} = useUserAction()
    const menuItem = useMemo(() => ({
            items: [
                {
                    icon: DashboardOutlined,
                    id: 'group-dashboard',
                    title: '',
                    type: 'group',
                    children: [
                        {
                            url: getRouteUrl(ROUTE_PAGE_TIRESIAS),
                            title: t("Tiresias"),
                            icon: HomeOutlined,
                            hoverMessage: t("Tiresias"),
                            permissions: ['access_tiresias'],
                            id: 'tiresias',
                            type: 'item',
                            breadcrumbs: false,
                        },
                        {
                            url: getRouteUrl(ROUTE_PAGE_USERS_INDEX),
                            title: t("Users"),
                            icon: PeopleOutlined,
                            hoverMessage: t("Users"),
                            permissions: ['access_user_index'],
                            id: 'users',
                            type: 'item',
                            breadcrumbs: false,
                        },
                        {
                            url: getRouteUrl(ROUTE_PAGE_HISTORY),
                            title: t("History"),
                            icon: HistoryIcon,
                            hoverMessage: t("History"),
                            permissions: ['access_history'],
                            id: 'history',
                            type: 'item',
                            breadcrumbs: false,
                        }
                    ]
                },
               /* {
                    icon: SettingsOutlined,
                    id: 'parameters',
                    title: t('Parameters'),
                    type: 'group',
                    permissions: ['access_parameters'],
                    children: [
                        {
                            icon: Groups2Outlined,
                            id: 'user-settings',
                            title: t('Users'),
                            type: 'collapse',
                            permissions: ['access_parameters'],
                            children: [
                                {
                                    url: getRouteUrl(ROUTE_PAGE_PARAMETERS_ROLES),
                                    title: t("Roles"),
                                    icon: FiberManualRecordOutlined,
                                    hoverMessage: t("Roles"),
                                    permissions: ['access_parameters'],
                                    id: 'roles',
                                    type: 'item',
                                    breadcrumbs: false,
                                },
                                {
                                    url: getRouteUrl(ROUTE_PAGE_PARAMETERS_RIGHTS),
                                    title: t("Rights"),
                                    icon: FiberManualRecordOutlined,
                                    hoverMessage: t("Rights"),
                                    permissions: ['access_parameters'],
                                    id: 'rights',
                                    type: 'item',
                                    breadcrumbs: false,
                                },
                                {
                                    url: getRouteUrl(ROUTE_PAGE_PARAMETERS_RIGHTS_ROLES),
                                    title: t("Rights and Roles"),
                                    icon: FiberManualRecordOutlined,
                                    hoverMessage: t("Rights and Roles"),
                                    permissions: ['access_parameters'],
                                    id: 'rights-roles',
                                    type: 'item',
                                    breadcrumbs: false,
                                }
                            ]
                        },
                    ]
                }*/
            ]
        })
        , [t])

    useEffect(() => {
        handlerMenuItem();
        // eslint-disable-next-line
    }, []);

    let getMenu = Menu();
    const handlerMenuItem = () => {
        const isFound = menuItem.items.some((element) => {
            return element.id === 'group-dashboard';
        });

        if (getMenu?.id !== undefined && !isFound) {
            menuItem.items.splice(0, 0, getMenu);
            setMenuItems(menuItem);
        }
    };

    useLayoutEffect(() => {
        setMenuItems(menuItem);
        // eslint-disable-next-line
    }, [menuItem]);

    const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

    const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
    let lastItemIndex = menuItems.items.length - 1;
    let remItems = [];
    let lastItemId;

    //  first it checks menu item is more than giving HORIZONTAL_MAX_ITEM after that get lastItemid by giving horizontal max
    // item, and it sets horizontal menu by giving horizontal max item lastly slice menuItem from array and set into remItems

    if (lastItem && lastItem < menuItems.items.length) {
        lastItemId = menuItems.items[lastItem - 1].id;
        lastItemIndex = lastItem - 1;
        remItems = menuItems.items.slice(lastItem - 1, menuItems.items.length).map((item) => ({
            title: item.title,
            elements: item.children,
            icon: item.icon
        }));
    }

    const navGroups = menuItems.items.slice(0, lastItemIndex + 1).map((item) => {
        switch (item.type) {
            case 'group':
                return (
                    <NavGroup
                        key={item.id}
                        setSelectedItems={setSelectedItems}
                        setSelectedLevel={setSelectedLevel}
                        selectedLevel={selectedLevel}
                        selectedItems={selectedItems}
                        lastItem={lastItem}
                        remItems={remItems}
                        lastItemId={lastItemId}
                        item={item}
                    />
                );
            default:
                debugger
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });
    return (
        <Box
            sx={{
                pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
                '& > ul:first-of-type': {mt: 0},
                display: isHorizontal ? {xs: 'block', lg: 'flex'} : 'block'
            }}
        >
            {navGroups}
            <NavItem
                item={{
                    url: '',
                    title: t("Logout"),
                    icon: LogoutOutlined,
                    hoverMessage: t("Logout"),
                    id: 'logout',
                    type: 'item',
                    breadcrumbs: false,
                }}
                onClickAction={userLogout}
                level={1}
            />
        </Box>
    );
};

export default Navigation;
