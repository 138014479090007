// @flow
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";
import useUserAction from "../../hooks/user/useUserAction";
import useUserData from "../../hooks/user/useUserData";
import Grid from '@mui/material/Grid';
import {useHistory} from 'react-router-dom'
import {LoginForm} from "../../components/loginForm/loginForm";
import * as yup from "yup";
import {useFormik} from "formik";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../helpers/errorHandling";
import {Stack, Typography} from "@mui/material";
import AuthWrapper from "../../theme/mantis/sections/auth/authWrapper";
import {useTheme} from "@mui/material/styles";
import useConfig from "../../theme/mantis/hooks/useConfig";
import LogoTeiresias from "../../theme/mantis/components/logo/logoTeiresias";

const Login = () => {
    const {t} = useTranslation(PAGE_LOGIN)
    const {userLogin, checkAPIConnectivity} = useUserAction()
    const {loading, errorLogin, errorConnectivity} = useUserData()
    const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
    const handleResetPasswordOpen = (open) => setResetPasswordOpen(open)
    const onSubmit = (values) => {
        userLogin(values)
    }

    const validationSchema = yup.object({
        username: yup
            .string()
            .required(t('required')),
        password: yup
            .string()
            .required(t('required')),
    })

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            rememberMe: false,
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    })

    // useEffect(() => {
    //     checkAPIConnectivity()
    // }, [checkAPIConnectivity])

    useEffect(() => {
        if (errorLogin) {
            enqueueSnackbar(handleAxiosErrors(errorLogin, t(errorLogin)), {
                    variant: 'error'
                }
            )
        }
    }, [errorLogin])

    // useEffect(() => {
    //     if (errorConnectivity) {
    //         enqueueSnackbar(handleAxiosErrors(errorConnectivity, t(errorConnectivity)), {
    //                 variant: 'error'
    //             }
    //         )
    //     }
    // }, [errorConnectivity])

    return (
        <AuthWrapper>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack direction="column" justifyContent="center" alignItems="center"
                           sx={{mb: {xs: -0.5, sm: 0.5}}}>
                        <Typography variant="h3">{t('Login')}</Typography>
                        <LogoTeiresias/>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <LoginForm
                        formik={formik}
                        loading={loading}
                        handleResetPasswordOpen={handleResetPasswordOpen}
                        resetPasswordOpen={resetPasswordOpen}/>
                </Grid>
            </Grid>
        </AuthWrapper>
    )
}

export default Login