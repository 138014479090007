import * as Namespaces from './namespaces'
import {API, API_DOMAIN, API_PORT} from "../../helpers/constants";

const locales = {
    [Namespaces.PAGE_LOGIN]: {
        el: {
            'Unauthorized': 'Ο χρήστης δεν έχει πρόσβαση στην ιστοσελίδα.',
            "Attempt to read property \"isActive\" on null": "Πρέπει πρώτα να επαληθεύσετε την ηλεκτρονική σας διεύθυνση για να συνδεθείτε. Ελέγξτε το email σας.",
            'Unauthorized. Wrong Username or Password': 'Ο χρήστης δεν έχει πρόσβαση στην ιστοσελίδα. Ελέγξετε ότι το όνομα χρήστη και ο κωδικός σας είναι σωστά.',
            'Inactive User': 'Ανενεργός Χρήστης',
            'Access failed!': `Η πρόσβαση στο ${API} απέτυχε! Παρακαλώ ελέγξτε το δίκτυό σας και το firewall. Η πρόσβαση στο port ${API_PORT} του domain ${API_DOMAIN} πρέπει να είναι ανοιχτή για να συνεχίσετε.`,
            'Sign In': 'Είσοδος',
            required: 'Συμπληρώστε το πεδίο',
            User: 'Χρήστης',
            Password: 'Κωδικός',
            login: 'ΣΥΝΔΕΣΗ',
            'Login': 'Σύνδεση',
            reset: 'ΕΠΑΝΑΦΟΡΑ',
            CANCEL: 'ΑΚΥΡΩΣΗ',
            loading: 'Φόρτωση',
            'Forgot your password?': 'Ξεχάσατε τον κωδικό;',
            'reset_password_message': 'Συμπληρώστε το email σας και ακολουθήστε τις οδηγίες.',
            'Press here': 'Πατήστε εδώ',
            'Network Error': 'Σφάλμα δικτύου.',
            'Unauthenticated.': 'Χωρίς εξουσιοδότηση.',
            Username: 'Όνομα Χρήστη',
            'Reset Password': 'Επαναφορά Κωδικού',
            'Fill in your email and you will receive a message to reset your password.': 'Συμπληρώστε το email σας και θα λάβετε ένα μήνυμα για να επαναφέρετε τον κωδικό πρόσβασής σας.',
            Send: 'Αποστολή',
            'Enter a valid email': 'Εισαγάγετε έγκυρο email',
            'Forgot password?': 'Ξεχάσατε τον κωδικό σας;',
            'Don\'t have an account? Sign Up': 'Δεν έχετε λογαριασμό;',
            'Loan Calculator': 'Υπολογισμός Δανείου',
            'Create': 'Δημιουργία',
            'Create Account': 'Δημιουργία Λογαριασμού',
            'Confirm Password': 'Επαλήθευση Κωδικού',
            'Submit': 'ΚΑΤΑΧΩΡΙΣΗ',
            'The email you entered was not found!': 'Δεν βρέθηκε το email που καταχωρίσατε!',
            'Learn more about IMS Company': 'Μάθετε περισσότερα για την IMS',
            'Calculation of mortgage installment': 'Υπολογισμός δόσης στεγαστικού δανείου',
            'Already have an account?': 'Έχετε ήδη λογαριασμό;',
            'Do not forgot to check SPAM box.': 'Μην ξεχάσετε να ελέγξετε και τα ανεπιθύμητα email σας.',
            "Enter your username": "Εισαγάγετε το όνομα χρήστη σας",
            "Enter password": "Εισαγάγετε κωδικό",
            "By Signing up, you agree to our": "Με την εγγραφή σας, συμφωνείτε με τους",
            'Terms of Service': ' Όρους Παροχής Υπηρεσιών',
            'Privacy policy': 'Πολιτική απορρήτου',
            'and': 'και την',
            'About us': 'Σχετικά με εμάς',
            'Privacy Statement': 'Δήλωση Απορρήτου',
            'This privacy statement was last updated on 26 January 2023 and applies to citizens and legal permanent residents of the European Economic Area and Switzerland.': 'Η παρούσα Δήλωση Απορρήτου επικαιροποιήθηκε στις 26 Ιανουάριος 2023 και ισχύει για τους πολίτες και τους νόμιμους μόνιμους κατοίκους του Ευρωπαϊκού Οικονομικού Χώρου και της Ελβετίας.',
            'this_privacy_statement': 'Στην παρούσα δήλωση απορρήτου, εξηγούμε τι κάνουμε με τα δεδομένα που λαμβάνουμε σχετικά με εσάς μέσω του https://www.ims-fc.gr. Σας συνιστούμε να διαβάσετε προσεκτικά την παρούσα δήλωση. Στην επεξεργασία μας συμμορφωνόμαστε με τις απαιτήσεις της νομοθεσίας περί απορρήτου. Αυτό σημαίνει, μεταξύ άλλων, ότι:',
            'we clearly state the purposes for which we process personal data. We do this by means of this privacy statement;': 'δηλώνουμε σαφώς τους σκοπούς για τους οποίους επεξεργαζόμαστε τα δεδομένα προσωπικού χαρακτήρα. Αυτό το κάνουμε μέσω της παρούσας δήλωσης απορρήτου.',
            'we aim to limit our collection of personal data to only the personal data required for legitimate purposes;': 'στόχος μας είναι να περιορίσουμε τη συλλογή δεδομένων προσωπικού χαρακτήρα μόνο στα δεδομένα προσωπικού χαρακτήρα που απαιτούνται για νόμιμους σκοπούς.',
            'we first request your explicit consent to process your personal data in cases requiring your consent;': 'αιτούμαστε πρώτα τη ρητή συγκατάθεσή σας για την επεξεργασία των δεδομένων σας προσωπικού χαρακτήρα σε περιπτώσεις που απαιτούν τη συγκατάθεσή σας,',
            'we take appropriate security measures to protect your personal data and also require this from parties that process personal data on our behalf;': 'λαμβάνουμε τα κατάλληλα μέτρα ασφαλείας για την προστασία των δεδομένων σας προσωπικού χαρακτήρα και το απαιτούμε επίσης από τα μέρη που επεξεργάζονται δεδομένα προσωπικού χαρακτήρα για λογαριασμό μας.',
            'we respect your right to access your personal data or have it corrected or deleted, at your request.': 'σεβόμαστε το δικαίωμά σας να έχετε πρόσβαση στα δεδομένα σας προσωπικού χαρακτήρα ή να τα διορθώνουμε ή να τα διαγράφουμε, κατόπιν αιτήματός σας.',
            'If you have any questions, or want to know exactly what data we keep of you, please contact us.': 'Εάν έχετε οποιεσδήποτε ερωτήσεις, ή θέλετε να μάθετε ακριβώς ποια δεδομένα διατηρούμε για εσάς, επικοινωνήστε μαζί μας.',
            '1. Purpose, data and retention period': '1. Σκοπός, δεδομένα και περίοδος διατήρησης',
            'personal_information': 'Ενδέχεται να συλλέγουμε ή να λάμβάνουμε πληροφορίες προσωπικού χαρακτήρα για διάφορους σκοπούς, που σχετίζονται με τις επιχειρηματικές μας δραστηριότητες, οι οποίοι μπορεί να περιλαμβάνουν τα ακόλουθα: (κάντε κλικ για ανάπτυξη)',
            '1.1 Contact - Through phone, mail, email and/or webforms': '1.1 Επικοινωνία - Μέσω τηλεφώνου, ταχυδρομείου, ηλεκτρονικού ταχυδρομείου ή/και ηλεκτρονικές φόρμες',
            'following_data': 'Για τον σκοπό αυτό χρησιμοποιούμε τα ακόλουθα δεδομένα:',
            'A first and last name': 'Ένα όνομα και επώνυμο',
            'A home or other physical address, including street name and name of a city or town': 'Μια διεύθυνση κατοικίας ή άλλη φυσική διεύθυνση, συμπεριλαμβανομένου του ονόματος του δρόμου και της πόλης ή κωμόπολης',
            'An email address': 'Μια διεύθυνση ηλεκτρονικού ταχυδρομείου',
            'Geolocation data': 'Δεδομένα γεωεντοπισμού',
            'Financial information such as bank account number or credit card number': 'Χρηματοπιστωτικές πληροφορίες όπως αριθμός τραπεζικού λογαριασμού ή αριθμός πιστωτικής κάρτας',
            'process_data': 'Η βάση επί της οποίας μπορούμε να επεξεργαστούμε αυτά τα δεδομένα είναι:',
            'Upon the provision of consent.': 'Με την παροχή συγκατάθεσης.',
            'Retention period': 'Περίοδος διατήρησης',
            'We retain this data until the service is terminated.': 'Διατηρούμε αυτά τα δεδομένα μέχρι να τερματιστεί η υπηρεσία.',
            '2. Cookies': '2. Cookies',
            'Our website uses cookies. For more information about cookies, please refer to our Cookie Policy.': 'Ο ιστότοπός μας χρησιμοποιεί cookies. Για περισσότερες πληροφορίες σχετικά με τα cookies, ανατρέξτε στην Πολιτική Cookies. ',
            '3. Disclosure practices': '3. Πρακτικές γνωστοποίησης',
            'We disclose personal information if we are required by law or by a court order, in response to a law enforcement agency, to the extent permitted under other provisions of law, to provide information, or for an investigation on a matter related to public safety.': 'Γνωστοποιούμε πληροφορίες προσωπικού χαρακτήρα εάν απαιτείται από τον νόμο ή από δικαστική απόφαση, ως απάντηση σε μια υπηρεσία επιβολής του νόμου, στον βαθμό που επιτρέπεται από άλλες διατάξεις του νόμου, να παρέχουμε πληροφορίες, ή για έρευνα σχετικά με θέμα που σχετίζεται με τη δημόσια ασφάλεια.',
            'If our website or organisation is taken over, sold, or involved in a merger or acquisition, your details may be disclosed to our advisers and any prospective purchasers and will be passed on to the new owners.': 'Εάν ο ιστότοπος ή ο οργανισμός μας εξαγοραστεί, πωληθεί ή εμπλακεί σε συγχώνευση ή εξαγορά, τα στοιχεία σας ενδέχεται να γνωστοποιηθούν στους συμβούλους μας και σε τυχόν υποψήφιους αγοραστές και θα μεταβιβαστούν στους νέους ιδιοκτήτες.',
            'We have concluded a data Processing Agreement with Google.': 'Έχουμε συνάψει μια Συμφωνία Επεξεργασίας δεδομένων με την Google.',
            'The inclusion of full IP addresses is blocked by us.': 'Η συμπερίληψη πλήρων διευθύνσεων IP εμποδίζεται από εμάς.',
            '4. Security': '4. Ασφάλεια',
            'We are committed to the security of personal data. We take appropriate security measures to limit abuse of and unauthorized access to personal data. This ensures that only the necessary persons have access to your data, that access to the data is protected, and that our security measures are regularly reviewed.': 'Δεσμευόμαστε για την ασφάλεια των δεδομένων προσωπικού χαρακτήρα. Λαμβάνουμε τα κατάλληλα μέτρα ασφαλείας για τον περιορισμό της κατάχρησης και της μη εξουσιοδοτημένης πρόσβασης σε δεδομένα προσωπικού χαρακτήρα. Αυτό διασφαλίζει ότι μόνο τα απαραίτητα άτομα έχουν πρόσβαση στα δεδομένα σας, ότι η πρόσβαση στα δεδομένα προστατεύεται και ότι τα μέτρα ασφαλείας μας επανεξετάζονται τακτικά.',
            '5. Third-party websites': '5. Ιστότοποι τρίτων μερών',
            'This privacy statement does not apply to third-party websites connected by links on our website. We cannot guarantee that these third parties handle your personal data in a reliable or secure manner. We recommend you read the privacy statements of these websites prior to making use of these websites.': 'Η παρούσα Δήλωση Απορρήτου δεν ισχύει για ιστότοπους τρίτων μερών που συνδέονται με συνδέσμους στον ιστότοπό μας. Δεν μπορούμε να εγγυηθούμε ότι τα εν λόγω τρίτα μέρη χειρίζονται τα δεδομένα σας προσωπικού χαρακτήρα με αξιόπιστο ή ασφαλή τρόπο. Σας συνιστούμε να διαβάσετε τις δηλώσεις απορρήτου των εν λόγω ιστότοπων πριν κάνετε χρήση τους.',
            '6. Amendments to this privacy statement': '6. Τροποποιήσεις της παρούσας δήλωσης απορρήτου',
            'We reserve the right to make amendments to this privacy statement. It is recommended that you consult this privacy statement regularly in order to be aware of any changes. In addition, we will actively inform you wherever possible.': 'Διατηρούμε το δικαίωμα να προβούμε σε τροποποιήσεις στην παρούσα δήλωση απορρήτου. Προτείνεται να συμβουλεύεστε τακτικά την παρούσα δήλωση απορρήτου, προκειμένου να γνωρίζετε τυχόν αλλαγές. Επιπλέον, θα σας ενημερώνουμε ενεργά όπου είναι δυνατό.',
            '7. Accessing and modifying your data': '7. Πρόσβαση και τροποποίηση των δεδομένων σας',
            'following_rights': 'Εάν έχετε οποιεσδήποτε ερωτήσεις ή θέλετε να μάθετε ποια δεδομένα προσωπικού χαρακτήρα διατηρούμε σχετικά με εσάς, παρακαλούμε επικοινωνήστε μαζί μας. Μπορείτε να επικοινωνήσετε μαζί μας χρησιμοποιώντας τα παρακάτω στοιχεία. Έχετε τα ακόλουθα δικαιώματα:',
            'You have the right to know why your personal data is needed, what will happen to it, and how long it will be retained for.': 'Έχετε το δικαίωμα να γνωρίζετε γιατί χρειάζονται τα δεδομένα σας προσωπικού χαρακτήρα, τι θα συμβεί σε αυτά και για πόσο χρονικό διάστημα θα διατηρούνται.',
            'right_access': 'Δικαίωμα πρόσβασης: Έχετε το δικαίωμα πρόσβασης στα δεδομένα σας προσωπικού χαρακτήρα που είναι γνωστά σε εμάς.',
            'right_rectification': 'Δικαίωμα διόρθωσης: Έχετε το δικαίωμα να συμπληρώσετε, να διορθώσετε, να διαγράψετε ή να αποκλείσετε τα δεδομένα σας προσωπικού χαρακτήρα όποτε το επιθυμείτε.',
            'If you give us your consent to process your data, you have the right to revoke that consent and to have your personal data deleted.': 'Εάν μας δώσετε τη συγκατάθεσή σας για την επεξεργασία των δεδομένων σας, έχετε το δικαίωμα να άρετε αυτήν τη συγκατάθεση και να διαγράψετε τα δεδομένα σας προσωπικού χαρακτήρα.',
            'right_transfer': 'Δικαίωμα διαβίβασης των δεδομένων σας: Έχετε το δικαίωμα να ζητήσετε όλα τα δεδομένα σας προσωπικού χαρακτήρα από τον Υπεύθυνο Επεξεργασίας και να τα διαβιβάσετε στο σύνολό τους, σε άλλον Υπεύθυνο Επεξεργασίας.',
            'right_object': 'Δικαίωμα εναντίωσης: Μπορείτε να αντιταχτείτε στην επεξεργασία των δεδομένων σας. Συμμορφωνόμαστε με αυτό, εκτός εάν υπάρχουν βάσιμοι λόγοι επεξεργασίας.',
            'Please make sure to always clearly state who you are, so that we can be certain that we do not modify or delete any data of the wrong person.': 'Παρακαλούμε φροντίστε να δηλώνετε πάντα με σαφήνεια ποιος είστε, ώστε να μπορούμε να είμαστε σίγουροι ότι δεν τροποποιούμε ή δεν διαγράφουμε δεδομένα του λάθος ατόμου.',
            '8. Submitting a complaint': '8. Υποβολή παραπόνου',
            'If you are not satisfied with the way in which we handle (a complaint about) the processing of your personal data, you have the right to submit a complaint to the Data Protection Authority.': 'Εάν δεν είστε ικανοποιημένοι από τον τρόπο με τον οποίο χειριζόμαστε (ένα παράπονο σχετικά με) την επεξεργασία των δεδομένων σας προσωπικού χαρακτήρα, έχετε το δικαίωμα να υποβάλετε καταγγελία στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα.',
            '9. Data Protection Officer': '9. Υπεύθυνος Προστασίας Δεδομένων',
            'Our Data Protection Officer has been registered with the data protection authority in an EU Member State. If you have any questions or requests with respect to this privacy statement or for the Data Protection Officer, you may contact ΜΑΡΙΑ ΚΑΜΠΟΥΡΗ, via www.ims-fc.gr or mkampouri@ims-fc.gr or by telephone on 2109513111': 'Ο Υπεύθυνος Προστασίας Δεδομένων μας έχει καταχωριθεί στην αρχή προστασίας δεδομένων σε κράτος μέλος της ΕΕ. Εάν έχετε οποιεσδήποτε ερωτήσεις ή αιτήματα σχετικά με την παρούσα δήλωση απορρήτου ή τον Υπεύθυνο Προστασίας Δεδομένων, μπορείτε να επικοινωνήσετε με τον/την ΜΑΡΙΑ ΚΑΜΠΟΥΡΗ, μέσω www.ims-fc.gr ή mkampouri@ims-fc.gr ή τηλεφωνικά στο 2109513111',
            '10. Contact details': '10. Στοιχεία επικοινωνίας',
            'ΚΑΜΠΟΥΡΗΣ ΚΥΡΙΑΚΟΣ ΜΑΥΡΑΚΗΣ ΙΩΑΝΝΗΣ ΟΕ': 'ΚΑΜΠΟΥΡΗΣ ΚΥΡΙΑΚΟΣ ΜΑΥΡΑΚΗΣ ΙΩΑΝΝΗΣ ΟΕ',
            'Λ. Ανδρέα Συγγρού 240, TK 176 72 Καλλιθέα': 'Λ. Ανδρέα Συγγρού 240, TK 176 72 Καλλιθέα',
            'Greece': 'Ελλάδα',
            'website': 'Ιστότοπος:',
            'privacy_email': 'Ηλεκτρονικό ταχυδρομείο: mkampouri@ims-fc.gr',
            'phone_number': 'Αριθμός τηλεφώνου: 2109520047'

        },
        en: {
            'this_privacy_statement': 'In this privacy statement, we explain what we do with the data we obtain about you via . We recommend you carefully read this statement. In our processing we comply with the requirements of privacy legislation. That means, among other things, that:',
            'personal_information': 'We may collect or receive personal information for a number of purposes connected with our business operations which may include the following: (click to expand)',
            'process_data': 'The basis on which we may process these data is:',
            'following_data': 'For this purpose we use the following data:',
            'following_rights': 'If you have any questions or want to know which personal data we have about you, please contact us. You can contact us by using the information below. You have the following rights:',
            'privacy_email': 'Email: mkampouri@ims-fc.gr',
            'right_access': 'Right of access: You have the right to access your personal data that is known to us.',
            'right_rectification': 'Right to rectification: you have the right to supplement, correct, have deleted or blocked your personal data whenever you wish.',
            'right_transfer': 'Right to transfer your data: you have the right to request all your personal data from the controller and transfer it in its entirety to another controller.',
            'right_object': 'Right to object: you may object to the processing of your data. We comply with this, unless there are justified grounds for processing.',
            'website': 'Website:',
            'phone_number': 'Phone number: 2109520047',
            'Access failed!': `Internet Access to ${API} failed! Please check your network and firewall. Access to port ${API_PORT} of domain ${API_DOMAIN} must not be blocked to continue.`,
            "Attempt to read property \"isActive\" on null": "You must first verify your email address to log in. Check your email.",
            reset: 'RESET',
            'reset_password_message': 'Enter your email and follow the instructions.',
            required: 'Required field',
            login: 'LOGIN',
            'Forgot password?': 'Forgot Password?',
            'Don\'t have an account? Sign Up': 'Don\'t have an account?'
        }
    },
    [Namespaces.PAGE_HOME]: {
        el: {
            'Loan Request': 'Αίτημα στεγαστικού δανείου',
            'Add Calculation': 'Προσθήκη Υπολογισμού',
            'Recommendations': 'Συστάσεις',
            'Number of recommendations': 'Αριθμός συστάσεων',
            'In progress': 'Υπό εξέλιξη',
            'Comprehensive recommendations': 'Ολοκληρωμένες συστάσεις',
            'Canceled recommendations': 'Ακυρωμένες συστάσεις',
            "Loans Information": "Πληροφορίες Δανείων",
            "Calculations Information": "Πληροφορίες Υπολογισμών",
            'No loan request has been registered yet.': 'Δεν έχει γίνει ακόμη καταχώριση αίτησης δανείου.',
            'Failed to get home page.': 'Αδυναμία φόρτωσης αρχικής σελίδας.',
            'Recommendation per month': 'Συστάσεις ανά μήνα',
            'Calculation of mortgage installment': 'Υπολογισμός δόσης στεγαστικού δανείου',
            'Privacy policy': 'Πολιτική απορρήτου',
            'Terms & Conditions': 'Όροι & Προϋποθέσεις',
            'This site is protected by': 'Αυτός ο ιστότοπος προστατεύεται από την ',
            'All rights reserved': 'Διατήρηση δικαιωμάτων',
            "My loans": "Τα δάνειά μου",
            "My calculations": "Οι υπολογισμοί μου",
            "Tiresias": "Τειρεσίας",
            'One year': 'Ένας χρόνος',
            'Two years': 'Δύο χρόνια',
            'Three years': 'Τρία χρόνια',

        },
        en: {}
    },
    [Namespaces.PAGE_RESET_PASSWORD]: {
        el: {
            'Reset Password': 'Επαναφορά Κωδικού',
            'Enter the six-digit code you received in the email': 'Εισαγάγετε τον εξαψήφιο κωδικό που λάβατε στο email',
            Password: 'Κωδικός',
            'Confirm Password': 'Επαλήθευση Κωδικού',
            'Password changed successfully!': 'Ο κωδικός άλλαξε με επιτυχία!',
            'The six digit number has expired!': 'Ο εξαψήφιος κωδικός έχει λήξει!',
            'Six-digit code send successfully!': 'Ο εξαψήφιος κωδικός στάλθηκε με επιτυχία!',
            'Login with different credentials': 'Σύνδεση με διαφορετικό λογαριασμό',
            'Send me new Code': 'Αποστολή νέου κωδικού',
            'Submit': 'Καταχώριση',
            'Required!': 'Απαιτείται!',
            'Enter exactly 6 characters.': 'Εισαγάγετε ακριβώς 6 χαρακτήρες.',
            'Password must be between 7-16 latin characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character': 'Ο κωδικός πρόσβασης πρέπει να αποτελείται από 7-16 λατινικούς χαρακτήρες και να περιέχει τουλάχιστον 3 από τα ακόλουθα. -Κεφάλαιο -Πεζό χαρακτήρα -Αριθμητικό ψηφίο -Ειδικός χαρακτήρας',
            'Passwords must match': 'Οι κωδικοί πρέπει να ταιριάζουν',
            'Wrong six-digit code!': 'Λάθος εξαψήφιος κωδικός!',
        },
        en: {}
    },
    [Namespaces.PAGE_TIRESIAS]: {
        el: {
            "Tiresias": "Τειρεσίας",
            "Surname": "Επώνυμο",
            "Enter surname": "Εισαγάγετε επώνυμο",
            "Name": "Όνομα",
            "Enter name": "Εισαγάγετε όνομα",
            "Father name": "Όνομα πατρός",
            "Enter father name": "Εισαγάγετε όνομα πατρός",
            "Identity No": "Αρ. ταυτότητας",
            "Enter identity No": "Εισαγάγετε αρ. ταυτότητας",
            "Mobile phone": "Κινητό τηλέφωνο",
            "Enter mobile phone": "Εισαγάγετε κινητό τηλέφωνο",
            "VAT": "ΑΦΜ",
            "Enter VAT": "Εισαγάγετε ΑΦΜ",
            "Enter email": "Εισαγάγετε email",
            "Responsible declaration code": "Κωδικός υπεύθυνης δήλωσης",
            "Enter declaration code": "Εισαγάγετε κωδικό δήλωσης",
            "Required": "Υποχρεωτικό",
            "Check": "Έλεγχος",
            "Successfully downloaded PDF!": "Το PDF κατέβηκε με επιτυχία!",
            'Failed to download PDF.': "Αποτυχία κατεβάσματος PDF.",
            "Enter a valid email": "Εισαγάγετε έγκυρο email.",
            "Loading... Please don't close the browser before the process ends.": "Φόρτωση... Μην κλείσετε το πρόγραμμα περιήγησης πριν τελειώσει η διαδικασία.",
            "Login Name": "Όνομα Χρήστη",
            "Person Name": "Επωνυμία Ατόμου",
            "Submit Date": "Ημερομηνία",
            "Vat Reg No": "ΑΦΜ",
            "History": "Ιστορικό"
        },
        en: {}
    },
}

export default locales