import * as ActionTypes from '../../actions/types'

const INITIAL_STATE = {
    openItem: ['dashboard'],
    openComponent: 'buttons',
    selectedID: null,
    drawerOpen: false,
    componentDrawerOpen: true,
    menu: {}
}

function setMenu(state, action) {
    const {payload} = action
    return state
        .set('menu', payload.menu)
}

function setMenuActiveItem(state, action) {
    const {payload} = action
    return state
        .set('openItem', payload.openItem)
}

function setMenuActiveId(state, action) {
    const {payload} = action
    return state
        .set('selectedID', payload)
}

function setMenuActiveComponent(state, action) {
    const {payload} = action
    return state
        .set('openComponent', payload.openComponent)
}

function setDrawerOpen(state, action) {
    const {payload} = action
    return state
        .set('drawerOpen', payload)
}

function setDrawerComponentOpen(state, action) {
    const {payload} = action
    return state
        .set('componentDrawerOpen', payload.componentDrawerOpen)
}

const menu = {
    initialState: INITIAL_STATE,
    handlers: {
        [ActionTypes.SET_MENU]: setMenu,
        [ActionTypes.SET_MENU_ACTIVE_ITEM]: setMenuActiveItem,
        [ActionTypes.SET_MENU_ACTIVE_ID]: setMenuActiveId,
        [ActionTypes.SET_MENU_ACTIVE_COMPONENT]: setMenuActiveComponent,
        [ActionTypes.SET_DRAWER_OPEN]: setDrawerOpen,
        [ActionTypes.SET_DRAWER_COMPONENT_OPEN]: setDrawerComponentOpen,
    }
}

export default menu
