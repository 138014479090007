import {useFormik} from "formik";
import * as yup from "yup";
import axios from "axios";
import {API} from "../../../helpers/constants";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {USERS} from "../locales/namespaces";
import useUserData from "../../../hooks/user/useUserData";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {ROUTE_PAGE_USERS_DETAILS} from "../routes/routes";
import {useHistory} from "react-router-dom";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import {enqueueSnackbar} from "notistack";
import useUserAction from "../../../hooks/user/useUserAction";

const useUserForm = ({
                         id,
                         user,
                         countries,
                         currencies,
                         languages,
                         nationalities
                     }) => {
    const {token, isLoggedIn} = useUserData()
    const {userLogout} = useUserAction()
    const history = useHistory()
    const {t} = useTranslation(USERS)
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = yup.object({
        username: yup
            .string()
            .required(t('Required')),
        password: !id ? yup.string()
            .required(t('Required'))
            .matches(/^.{60}$|(^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z\d])|(?=.*?[A-Z])(?=.*?\d)(?=.*?[^a-zA-Z\d])|(?=.*?[a-z])(?=.*?\d)(?=.*?[^a-zA-Z\d])).{7,16}$)/,
                t('Password must be between 7-16 latin characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character')) : yup.string().notRequired().matches(/^.{60}$|(^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z\d])|(?=.*?[A-Z])(?=.*?\d)(?=.*?[^a-zA-Z\d])|(?=.*?[a-z])(?=.*?\d)(?=.*?[^a-zA-Z\d])).{7,16}$)/,
            t('Password must be between 7-16 latin characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character')),
        confirmPassword: !id ? yup.string()
            .required(t('Required'))
            .oneOf([yup.ref('password'), null], t('Passwords must match')) : yup.string().test('passwords-match', t('Passwords must match'), function (value) {
            return this.parent.password === value
        }),
        LoginName: yup
            .string()
            .required(t(t('Required'))),
        email: yup
            .string()
            .required(t('Required'))
            .email(t('Enter a valid email')),
        country: yup.object().required(t('Required')),
        currency: yup.string().required(t('Required')),
        language: yup.object().required(t('Required')),
        nationality: yup.string().required(t('Required'))
    })

    const onSubmit = (values) => {
        setIsLoading(true)
        axios({
            method: 'post',
            data: {
                UserId: id,
                LoginName: values.LoginName,
                username: values.username,
                password: values.password,
                isActive: values.isActive,
                email: values.email,
                LangId: values.language.id,
                CurrencyId: values.currency,
                NationalityId: values.nationality,
                CountryId: values.country.id,
                isAdmin: values.isAdmin
            },
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: !id ? `${API}/user/store` : `${API}/user/update`
        }).then(response => {
            enqueueSnackbar(!id ? t('User created successfully!') : t('User updated successfully!'), {
                    variant: 'success',
                    autoHideDuration: 800,
                    onClose: () => history.push(getRouteUrl(ROUTE_PAGE_USERS_DETAILS, {id: response.data.userid}))
                }
            )
            setIsLoading(false)
        }).catch(error => {
            enqueueSnackbar(handleAxiosErrors(error, error?.data?.message, {
                    variant: 'error',
                }
            ))
            setIsLoading(false)
            logoutUnauthorizedUser(error, isLoggedIn, userLogout)
        })
    }

    const formik = useFormik({
        initialValues: {
            isActive: id ? user.isActive : true,
            LoginName: user?.LoginName || '',
            username: user?.username || '',
            password: user?.password || '',
            confirmPassword: user?.password || '',
            email: user?.email || '',
            currency: user?.CurrencyId ? currencies.find(f => f.id === user.CurrencyId)?.id : currencies.length > 0 ? currencies.find(f => f.isDefault === '1')?.id : '',
            language: user?.LangId ? languages.find(f => f.id === user.LangId) : languages.find(f => f.isDefault == 1),
            country: user?.CountryId ? countries.find(f => f.id === user.CountryId) : countries.find(f => f.isDefault == 1),
            nationality: user?.NationalityId ? nationalities.find(f => f.id === user.NationalityId)?.id : nationalities.length > 0 ? nationalities.find(f => f.isDefault === '1')?.id : '',
            isAdmin: user.isAdmin || false
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    })

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return {
        formik,
        showPassword,
        handleClickShowPassword,
        handleMouseDownPassword,
        isLoading
    }
}

export default useUserForm

