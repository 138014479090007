// @flow
import React, {Fragment, useState} from 'react';
import Button from "@mui/material/Button";
import {FormikProvider} from "formik";
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Grid, FormLabel, OutlinedInput, Stack, FormHelperText} from "@mui/material";
import AnimateButton from "../../theme/mantis/components/@extended/animateButton";
import {ThemeMode} from "../../theme/mantis/config";
import {useTheme} from "@mui/material/styles";

type Props = {
    formik: Object
};

export const LoginForm = ({formik, loading, resetPasswordOpen, handleResetPasswordOpen}: Props) => {
    const {t} = useTranslation(PAGE_LOGIN)
    const theme = useTheme();
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <Fragment>
            <style>
                {`input:-webkit-autofill {-webkit-box-shadow: 0 0 0 1000px ${theme.palette.mode === ThemeMode.LIGHT ? '#FFFFFF' : '#1E1E1E'} inset !important;}`}
            </style>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit} noValidate>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stack spacing={1}>
                                <FormLabel htmlFor="username">{t("Username")}</FormLabel>
                                <OutlinedInput
                                    id="username"
                                    value={formik.values.username}
                                    name="username"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t("Enter your username")}
                                    fullWidth
                                    error={Boolean(formik.touched.email && formik.errors.email)}
                                    autoComplete={'username'}
                                    autoFocus
                                />
                                {formik.touched.username && formik.errors.username && (
                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                        {formik.errors.email}
                                    </FormHelperText>
                                )}
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1}>
                                <FormLabel htmlFor="password">{t("Password")}</FormLabel>
                                <OutlinedInput
                                    fullWidth
                                    error={Boolean(formik.touched.password && formik.errors.password)}
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={formik.values.password}
                                    name="password"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    autoComplete={'current-password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                color="secondary"
                                            >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    placeholder={t("Enter password")}
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <FormHelperText error id="standard-weight-helper-text-password-login">
                                        {formik.errors.password}
                                    </FormHelperText>
                                )}
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <AnimateButton type={'slide'}>
                                <Button
                                    disableElevation
                                    disabled={loading} fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        bgcolor: '#2947AB',
                                        borderRadius: '4px'
                                    }}
                                >
                                    {t('Login')}
                                </Button>
                            </AnimateButton>
                        </Grid>
                    </Grid>
                </form>
            </FormikProvider>
        </Fragment>
    );
};